:root{
    --color-black:#000000;
    --color-white:#ffffff;
    --color-silver:#f2f2f2;
    --color-graphite:#222A43;
    --color-moscow:#FF4545;
    --color-heavenly:#6FE3FF;
    --color-sky-yellow:#FFCE39;
    --color-bright-space:#7B2AFF;
    --color-space-gray:#2B2D33;
    --color-danger:#dc2626;
    --color-label:#808080;
    --font-regular:'GolosTextWebRegular',sans-serif;
    --font-medium:'GolosTextWebMedium',sans-serif;
    --font-demibold:'GolosTextWebDemiBold',sans-serif;
    --font-bold:'GolosTextWebBold',sans-serif
    /* --font-regular:'Helvetica',sans-serif;
    --font-medium:'Helvetica',sans-serif;
    --font-demibold:'Helvetica',sans-serif;
    --font-bold:'Helvetica',sans-serif */
}
html{
    line-height:1.15;
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%;
    background-color:var(--color-silver)
}
*{
    box-sizing:border-box;
    margin:0;
    padding:0;
    border:0
}
:after,:before{
    box-sizing:border-box
}
.row:after{
    content:"";
    clear:both;
    display:block
}
ul li{
    list-style:none
}
article,aside,figcaption,figure,footer,header,main,nav,section{
    display:block
}
figure{
    margin:1em 40px
}
hr{
    box-sizing:content-box;
    height:0;
    overflow:visible
}
th{
    font-weight:400
}
a{
    background-color:transparent;
    -webkit-text-decoration-skip:objects;
    text-decoration:none;
    cursor:pointer
}
b,strong{
    font-weight:600
}
audio,video{
    display:inline-block
}
audio:not([controls]){
    display:none;
    height:0
}
img{
    border-style:none
}
svg:not(:root){
    overflow:hidden
}
button:focus,div:focus,img:focus,input:focus,select:focus,textarea:focus{
    outline:0
}
button,input,optgroup,select,textarea{
    margin:0
}
button,input{
    overflow:visible
}
button,select{
    text-transform:none
}
button{
    background-color:transparent;
    outline:0;
    cursor:pointer
}
[type=reset],[type=submit],button,html [type=button]{
    -webkit-appearance:button
}
[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{
    border-style:none;
    padding:0
}
textarea{
    overflow:auto
}
[type=checkbox],[type=radio]{
    box-sizing:border-box;
    padding:0;
    display:none
}
[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{
    height:auto
}
[type=search]{
    -webkit-appearance:textfield;
    outline-offset:-2px
}
[type=search]::-webkit-search-cancel-button,[type=search]::-webkit-search-decoration{
    -webkit-appearance:none
}
::-webkit-file-upload-button{
    -webkit-appearance:button;
    font:inherit
}
details,menu{
    display:block
}
[hidden]{
    display:none
}
::-webkit-scrollbar{
    width:8px;
    height:8px;
    background-color:#fafafa;
    opacity:.6
}
::-webkit-scrollbar-thumb{
    border-radius:10px;
    background-color:grey
}
::-webkit-scrollbar-track{
    border-radius:10px;
    background-color:#fafafa
}
::-moz-scrollbar{
    width:8px;
    height:8px;
    background-color:#fafafa;
    opacity:.6
}
::-moz-scrollbar-thumb{
    border-radius:10px;
    background-color:grey
}
::-moz-scrollbar-track{
    border-radius:10px;
    background-color:#fafafa
}
scrollbar{
    width:8px;
    height:8px;
    background-color:#fafafa;
    opacity:.6;
    appearance:none
}
::scrollbar-thumb{
    border-radius:10px;
    background-color:grey
}
::scrollbar-track{
    border-radius:10px;
    background-color:#fafafa
}
.d-fl{
    display:flex!important;
    flex:0 1 auto
}
.d-inl-fl{
    display:inline-flex!important;
    flex:0 1 auto
}
.d-b{
    display:block!important
}
.d-inl-b{
    display:inline-block!important
}
.d-inl{
    display:inline!important
}
.d-none{
    display:none!important
}
@media (min-width:370px){
    .d-3xs-fl{
        display:flex!important
    }
    .d-3xs-inl-fl{
        display:inline-flex!important;
        flex:0 1 auto
    }
    .d-3xs-b{
        display:block!important
    }
    .d-3xs-inl-b{
        display:inline-block!important
    }
    .d-3xs-inl{
        display:inline!important
    }
    .d-3xs-none{
        display:none!important
    }
}
@media (min-width:480px){
    .d-2xs-fl{
        display:flex!important
    }
    .d-2xs-inl-fl{
        display:inline-flex!important;
        flex:0 1 auto
    }
    .d-2xs-b{
        display:block!important
    }
    .d-2xs-inl-b{
        display:inline-block!important
    }
    .d-2xs-inl{
        display:inline!important
    }
    .d-2xs-none{
        display:none!important
    }
}
@media (min-width:640px){
    .d-xs-fl{
        display:flex!important
    }
    .d-xs-inl-fl{
        display:inline-flex!important;
        flex:0 1 auto
    }
    .d-xs-b{
        display:block!important
    }
    .d-xs-inl-b{
        display:inline-block!important
    }
    .d-xs-inl{
        display:inline!important
    }
    .d-xs-none{
        display:none!important
    }
}
@media (min-width:768px){
    .d-sm-fl{
        display:flex!important
    }
    .d-sm-inl-fl{
        display:inline-flex!important;
        flex:0 1 auto
    }
    .d-sm-b{
        display:block!important
    }
    .d-sm-inl-b{
        display:inline-block!important
    }
    .d-sm-inl{
        display:inline!important
    }
    .d-sm-none{
        display:none!important
    }
}
@media (min-width:1024px){
    .d-md-fl{
        display:flex!important
    }
    .d-md-inl-fl{
        display:inline-flex!important;
        flex:0 1 auto
    }
    .d-md-b{
        display:block!important
    }
    .d-md-inl-b{
        display:inline-block!important
    }
    .d-md-inl{
        display:inline!important
    }
    .d-md-none{
        display:none!important
    }
}
@media (min-width:1280px){
    .d-lg-fl{
        display:flex!important
    }
    .d-lg-inl-fl{
        display:inline-flex!important;
        flex:0 1 auto
    }
    .d-lg-b{
        display:block!important
    }
    .d-lg-inl-b{
        display:inline-block!important
    }
    .d-lg-inl{
        display:inline!important
    }
    .d-lg-none{
        display:none!important
    }
}
@media (min-width:1360px){
    .d-xl-fl{
        display:flex!important
    }
    .d-xl-inl-fl{
        display:inline-flex!important;
        flex:0 1 auto
    }
    .d-xl-b{
        display:block!important
    }
    .d-xl-inl-b{
        display:inline-block!important
    }
    .d-xl-inl{
        display:inline!important
    }
    .d-xl-none{
        display:none!important
    }
}
@media (min-width:1680px){
    .d-xxl-fl{
        display:flex!important
    }
    .d-xxl-inl-fl{
        display:inline-flex!important;
        flex:0 1 auto
    }
    .d-xxl-b{
        display:block!important
    }
    .d-xxl-inl-b{
        display:inline-block!important
    }
    .d-xxl-inl{
        display:inline!important
    }
    .d-xxl-none{
        display:none!important
    }
}
@font-face{
    font-family:GolosTextWebRegular;
    src:url('./Golos-Text_Regular.woff2') format('woff2');
    font-weight:400;
    font-style:normal
}
@font-face{
    font-family:GolosTextWebMedium;
    src:url('./Golos-Text_Medium.woff2') format('woff2');
    font-weight:400;
    font-style:normal
}
@font-face{
    font-family:GolosTextWebDemiBold;
    src:url('./Golos-Text_DemiBold.woff2') format('woff2');
    font-weight:400;
    font-style:normal
}
@font-face{
    font-family:GolosTextWebBold;
    src:url('./Golos-Text_Bold.woff2') format('woff2');
    font-weight:400;
    font-style:normal
}
/* @font-face{
    font-family:GolosTextWebVF;
    src:url('./Golos-Text_VF.woff2') format('woff2');
    font-weight:400;
    font-style:normal
} */
/* @font-face{
    font-family:clk;
    src:url('./clk.ttf?phnk0j') format('truetype'),url('./clk.svg?phnk0j#clk') format('svg');
    font-weight:400;
    font-style:normal;
    font-display:block
} */
[class*=" icon-"],[class^=icon-]{
    font-family:clk!important;
    speak:never;
    font-style:normal;
    font-weight:400;
    font-variant:normal;
    text-transform:none;
    line-height:1;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
}
.icon-telegram:before{
    content:"\e910";
    color:#039be5
}
.icon-vk:before{
    content:"\e911";
    color:#07f
}
.icon-trash:before{
    content:"\e90f";
    color:#dc2626
}
.icon-success:before{
    content:"\e90e";
    color:#34b012
}
.icon-user:before{
    content:"\e90d";
    color:#fff
}
.icon-back:before{
    content:"\e900";
    color:#fff
}
.icon-bus:before{
    content:"\e901";
    color:#ffce39
}
.icon-card:before{
    content:"\e902";
    color:#fff
}
.icon-chat:before{
    content:"\e903"
}
.icon-download:before{
    content:"\e904";
    color:#fff
}
.icon-job:before{
    content:"\e905";
    color:#ffce39
}
.icon-locate-fixed:before{
    content:"\e906";
    color:#fff
}
.icon-percent:before{
    content:"\e907";
    color:#fff
}
.icon-qr-code:before{
    content:"\e908";
    color:#fff
}
.icon-road:before{
    content:"\e909";
    color:#fff
}
.icon-roadmap:before{
    content:"\e90a";
    color:#fff
}
.icon-scholl:before{
    content:"\e90b";
    color:#ffce39
}
.icon-smile:before{
    content:"\e90c";
    color:#fff
}
.h-r,.t-r{
    font-family:var(--font-regular);
    font-weight:400
}
.h-m,.t-m{
    font-family:var(--font-medium);
    font-weight:500
}
.h-db,.t-db{
    font-family:var(--font-demibold);
    font-weight:600
}
.h-b,.t-b{
    font-family:var(--font-bold);
    font-weight:800
}
.t-white{
    color:var(--color-white)
}
.t-black{
    color:var(--color-space-gray)
}
.t-bs{
    color:var(--color-bright-space)
}
.t-c{
    text-align:center
}
.c-p{
    cursor:pointer
}
.h2{
    font-family:var(--font-demibold);
    font-weight:600;
    font-size:32px;
    line-height:36px
}
@media (min-width:640px){
    .h2{
        font-size:38px;
        line-height:48px
    }
}
@media (min-width:768px){
    .h2{
        font-size:48px;
        line-height:58px
    }
}
@media (min-width:1360px){
    .h2{
        font-size:52px;
        line-height:60px
    }
}
.h3{
    font-family:var(--font-demibold);
    font-weight:600;
    font-size:26px;
    line-height:32px
}
@media (min-width:768px){
    .h3{
        font-size:30px;
        line-height:38px
    }
}
.t-lg{
    font-size:18px;
    line-height:28px
}
@media (min-width:640px){
    .t-lg{
        font-size:20px;
        line-height:28px
    }
}
@media (min-width:768px){
    .t-lg{
        font-size:26px;
        line-height:34px
    }
}
@media (min-width:1280px){
    .t-lg{
        font-size:28px;
        line-height:38px
    }
}
.t-md{
    font-size:16px;
    line-height:22px
}
@media (min-width:768px){
    .t-md{
        font-size:24px;
        line-height:30px
    }
}
.t-sm{
    font-size:15px;
    line-height:19px
}
.t-xs{
    font-size:14px;
    line-height:18px
}
.fl-sr-0{
    flex-shrink:0!important
}
.fl-sr-1{
    flex-shrink:1!important
}
.fl-g{
    flex-grow:1!important
}
.fl-g-1{
    flex-grow:1!important
}
.fl-g-2{
    flex-grow:2!important
}
.fl-g-3{
    flex-grow:3!important
}
.fl-g-4{
    flex-grow:4!important
}
.fl-g-5{
    flex-grow:5!important
}
.fl-g-6{
    flex-grow:6!important
}
.fl-g-7{
    flex-grow:7!important
}
.fl-g-8{
    flex-grow:8!important
}
.fl-g-9{
    flex-grow:9!important
}
.fl-g-10{
    flex-grow:10!important
}
.fl-g-11{
    flex-grow:11!important
}
.fl-g-12{
    flex-grow:12!important
}
.fl-w{
    flex-wrap:wrap!important
}
.fl-nw{
    flex-wrap:nowrap!important
}
.fl-col{
    flex-direction:column!important
}
.fl-row{
    flex-direction:row!important
}
.ai-s{
    align-items:flex-start!important
}
.ai-c{
    align-items:center!important
}
.ai-bl{
    align-items:baseline!important
}
.ai-end{
    align-items:flex-end!important
}
.ai-str{
    align-items:stretch!important
}
.jc-s{
    justify-content:flex-start!important
}
.jc-c{
    justify-content:center!important
}
.jc-end{
    justify-content:flex-end!important
}
.jc-sb{
    justify-content:space-between!important
}
.jc-ev{
    justify-content:space-evenly!important
}
.jc-ar{
    justify-content:space-around!important
}
@media (min-width:370px){
    .fl-3xs-sr-0{
        flex-shrink:0!important
    }
    .fl-3xs-sr-1{
        flex-shrink:1!important
    }
    .fl-3xs-g{
        flex-grow:1!important
    }
    .fl-3xs-g-1{
        flex-grow:1!important
    }
    .fl-3xs-g-2{
        flex-grow:2!important
    }
    .fl-3xs-g-3{
        flex-grow:3!important
    }
    .fl-3xs-g-4{
        flex-grow:4!important
    }
    .fl-3xs-g-5{
        flex-grow:5!important
    }
    .fl-3xs-g-6{
        flex-grow:6!important
    }
    .fl-3xs-g-7{
        flex-grow:7!important
    }
    .fl-3xs-g-8{
        flex-grow:8!important
    }
    .fl-3xs-g-9{
        flex-grow:9!important
    }
    .fl-3xs-g-10{
        flex-grow:10!important
    }
    .fl-3xs-g-11{
        flex-grow:11!important
    }
    .fl-3xs-g-12{
        flex-grow:12!important
    }
    .fl-3xs-w{
        flex-wrap:wrap!important
    }
    .fl-3xs-nw{
        flex-wrap:nowrap!important
    }
    .fl-3xs-col{
        flex-direction:column!important
    }
    .fl-3xs-row{
        flex-direction:row!important
    }
    .ai-3xs-s{
        align-items:flex-start!important
    }
    .ai-3xs-c{
        align-items:center!important
    }
    .ai-3xs-bl{
        align-items:baseline!important
    }
    .ai-3xs-end{
        align-items:flex-end!important
    }
    .ai-3xs-str{
        align-items:stretch!important
    }
    .jc-3xs-s{
        justify-content:flex-start!important
    }
    .jc-3xs-c{
        justify-content:center!important
    }
    .jc-3xs-end{
        justify-content:flex-end!important
    }
    .jc-3xs-sb{
        justify-content:space-between!important
    }
    .jc-3xs-ev{
        justify-content:space-evenly!important
    }
    .jc-3xs-ar{
        justify-content:space-around!important
    }
}
@media (min-width:480px){
    .fl-2xs-sr-0{
        flex-shrink:0!important
    }
    .fl-2xs-sr-1{
        flex-shrink:1!important
    }
    .fl-2xs-g{
        flex-grow:1!important
    }
    .fl-2xs-g-1{
        flex-grow:1!important
    }
    .fl-2xs-g-2{
        flex-grow:2!important
    }
    .fl-2xs-g-3{
        flex-grow:3!important
    }
    .fl-2xs-g-4{
        flex-grow:4!important
    }
    .fl-2xs-g-5{
        flex-grow:5!important
    }
    .fl-2xs-g-6{
        flex-grow:6!important
    }
    .fl-2xs-g-7{
        flex-grow:7!important
    }
    .fl-2xs-g-8{
        flex-grow:8!important
    }
    .fl-2xs-g-9{
        flex-grow:9!important
    }
    .fl-2xs-g-10{
        flex-grow:10!important
    }
    .fl-2xs-g-11{
        flex-grow:11!important
    }
    .fl-2xs-g-12{
        flex-grow:12!important
    }
    .fl-2xs-w{
        flex-wrap:wrap!important
    }
    .fl-2xs-nw{
        flex-wrap:nowrap!important
    }
    .fl-2xs-col{
        flex-direction:column!important
    }
    .fl-2xs-row{
        flex-direction:row!important
    }
    .ai-2xs-s{
        align-items:flex-start!important
    }
    .ai-2xs-c{
        align-items:center!important
    }
    .ai-2xs-bl{
        align-items:baseline!important
    }
    .ai-2xs-end{
        align-items:flex-end!important
    }
    .ai-2xs-str{
        align-items:stretch!important
    }
    .jc-2xs-s{
        justify-content:flex-start!important
    }
    .jc-2xs-c{
        justify-content:center!important
    }
    .jc-2xs-end{
        justify-content:flex-end!important
    }
    .jc-2xs-sb{
        justify-content:space-between!important
    }
    .jc-2xs-ev{
        justify-content:space-evenly!important
    }
    .jc-2xs-ar{
        justify-content:space-around!important
    }
}
@media (min-width:640px){
    .fl-xs-sr-0{
        flex-shrink:0!important
    }
    .fl-xs-sr-1{
        flex-shrink:1!important
    }
    .fl-xs-g{
        flex-grow:1!important
    }
    .fl-xs-g-1{
        flex-grow:1!important
    }
    .fl-xs-g-2{
        flex-grow:2!important
    }
    .fl-xs-g-3{
        flex-grow:3!important
    }
    .fl-xs-g-4{
        flex-grow:4!important
    }
    .fl-xs-g-5{
        flex-grow:5!important
    }
    .fl-xs-g-6{
        flex-grow:6!important
    }
    .fl-xs-g-7{
        flex-grow:7!important
    }
    .fl-xs-g-8{
        flex-grow:8!important
    }
    .fl-xs-g-9{
        flex-grow:9!important
    }
    .fl-xs-g-10{
        flex-grow:10!important
    }
    .fl-xs-g-11{
        flex-grow:11!important
    }
    .fl-xs-g-12{
        flex-grow:12!important
    }
    .fl-xs-w{
        flex-wrap:wrap!important
    }
    .fl-xs-nw{
        flex-wrap:nowrap!important
    }
    .fl-xs-col{
        flex-direction:column!important
    }
    .fl-xs-row{
        flex-direction:row!important
    }
    .ai-xs-s{
        align-items:flex-start!important
    }
    .ai-xs-c{
        align-items:center!important
    }
    .ai-xs-bl{
        align-items:baseline!important
    }
    .ai-xs-end{
        align-items:flex-end!important
    }
    .ai-xs-str{
        align-items:stretch!important
    }
    .jc-xs-s{
        justify-content:flex-start!important
    }
    .jc-xs-c{
        justify-content:center!important
    }
    .jc-xs-end{
        justify-content:flex-end!important
    }
    .jc-xs-sb{
        justify-content:space-between!important
    }
    .jc-xs-ev{
        justify-content:space-evenly!important
    }
    .jc-xs-ar{
        justify-content:space-around!important
    }
}
@media (min-width:768px){
    .fl-sm-sr-0{
        flex-shrink:0!important
    }
    .fl-sm-sr-1{
        flex-shrink:1!important
    }
    .fl-sm-g{
        flex-grow:1!important
    }
    .fl-sm-g-1{
        flex-grow:1!important
    }
    .fl-sm-g-2{
        flex-grow:2!important
    }
    .fl-sm-g-3{
        flex-grow:3!important
    }
    .fl-sm-g-4{
        flex-grow:4!important
    }
    .fl-sm-g-5{
        flex-grow:5!important
    }
    .fl-sm-g-6{
        flex-grow:6!important
    }
    .fl-sm-g-7{
        flex-grow:7!important
    }
    .fl-sm-g-8{
        flex-grow:8!important
    }
    .fl-sm-g-9{
        flex-grow:9!important
    }
    .fl-sm-g-10{
        flex-grow:10!important
    }
    .fl-sm-g-11{
        flex-grow:11!important
    }
    .fl-sm-g-12{
        flex-grow:12!important
    }
    .fl-sm-w{
        flex-wrap:wrap!important
    }
    .fl-sm-nw{
        flex-wrap:nowrap!important
    }
    .fl-sm-col{
        flex-direction:column!important
    }
    .fl-sm-row{
        flex-direction:row!important
    }
    .ai-sm-s{
        align-items:flex-start!important
    }
    .ai-sm-c{
        align-items:center!important
    }
    .ai-sm-bl{
        align-items:baseline!important
    }
    .ai-sm-end{
        align-items:flex-end!important
    }
    .ai-sm-str{
        align-items:stretch!important
    }
    .jc-sm-s{
        justify-content:flex-start!important
    }
    .jc-sm-c{
        justify-content:center!important
    }
    .jc-sm-end{
        justify-content:flex-end!important
    }
    .jc-sm-sb{
        justify-content:space-between!important
    }
    .jc-sm-ev{
        justify-content:space-evenly!important
    }
    .jc-sm-ar{
        justify-content:space-around!important
    }
}
@media (min-width:1024px){
    .fl-md-sr-0{
        flex-shrink:0!important
    }
    .fl-md-sr-1{
        flex-shrink:1!important
    }
    .fl-md-g{
        flex-grow:1!important
    }
    .fl-md-g-1{
        flex-grow:1!important
    }
    .fl-md-g-2{
        flex-grow:2!important
    }
    .fl-md-g-3{
        flex-grow:3!important
    }
    .fl-md-g-4{
        flex-grow:4!important
    }
    .fl-md-g-5{
        flex-grow:5!important
    }
    .fl-md-g-6{
        flex-grow:6!important
    }
    .fl-md-g-7{
        flex-grow:7!important
    }
    .fl-md-g-8{
        flex-grow:8!important
    }
    .fl-md-g-9{
        flex-grow:9!important
    }
    .fl-md-g-10{
        flex-grow:10!important
    }
    .fl-md-g-11{
        flex-grow:11!important
    }
    .fl-md-g-12{
        flex-grow:12!important
    }
    .fl-md-w{
        flex-wrap:wrap!important
    }
    .fl-md-nw{
        flex-wrap:nowrap!important
    }
    .fl-md-col{
        flex-direction:column!important
    }
    .fl-md-row{
        flex-direction:row!important
    }
    .ai-md-s{
        align-items:flex-start!important
    }
    .ai-md-c{
        align-items:center!important
    }
    .ai-md-bl{
        align-items:baseline!important
    }
    .ai-md-end{
        align-items:flex-end!important
    }
    .ai-md-str{
        align-items:stretch!important
    }
    .jc-md-s{
        justify-content:flex-start!important
    }
    .jc-md-c{
        justify-content:center!important
    }
    .jc-md-end{
        justify-content:flex-end!important
    }
    .jc-md-sb{
        justify-content:space-between!important
    }
    .jc-md-ev{
        justify-content:space-evenly!important
    }
    .jc-md-ar{
        justify-content:space-around!important
    }
}
@media (min-width:1280px){
    .fl-lg-sr-0{
        flex-shrink:0!important
    }
    .fl-lg-sr-1{
        flex-shrink:1!important
    }
    .fl-lg-g{
        flex-grow:1!important
    }
    .fl-lg-g-1{
        flex-grow:1!important
    }
    .fl-lg-g-2{
        flex-grow:2!important
    }
    .fl-lg-g-3{
        flex-grow:3!important
    }
    .fl-lg-g-4{
        flex-grow:4!important
    }
    .fl-lg-g-5{
        flex-grow:5!important
    }
    .fl-lg-g-6{
        flex-grow:6!important
    }
    .fl-lg-g-7{
        flex-grow:7!important
    }
    .fl-lg-g-8{
        flex-grow:8!important
    }
    .fl-lg-g-9{
        flex-grow:9!important
    }
    .fl-lg-g-10{
        flex-grow:10!important
    }
    .fl-lg-g-11{
        flex-grow:11!important
    }
    .fl-lg-g-12{
        flex-grow:12!important
    }
    .fl-lg-w{
        flex-wrap:wrap!important
    }
    .fl-lg-nw{
        flex-wrap:nowrap!important
    }
    .fl-lg-col{
        flex-direction:column!important
    }
    .fl-lg-row{
        flex-direction:row!important
    }
    .ai-lg-s{
        align-items:flex-start!important
    }
    .ai-lg-c{
        align-items:center!important
    }
    .ai-lg-bl{
        align-items:baseline!important
    }
    .ai-lg-end{
        align-items:flex-end!important
    }
    .ai-lg-str{
        align-items:stretch!important
    }
    .jc-lg-s{
        justify-content:flex-start!important
    }
    .jc-lg-c{
        justify-content:center!important
    }
    .jc-lg-end{
        justify-content:flex-end!important
    }
    .jc-lg-sb{
        justify-content:space-between!important
    }
    .jc-lg-ev{
        justify-content:space-evenly!important
    }
    .jc-lg-ar{
        justify-content:space-around!important
    }
}
@media (min-width:1360px){
    .fl-xl-sr-0{
        flex-shrink:0!important
    }
    .fl-xl-sr-1{
        flex-shrink:1!important
    }
    .fl-xl-g{
        flex-grow:1!important
    }
    .fl-xl-g-1{
        flex-grow:1!important
    }
    .fl-xl-g-2{
        flex-grow:2!important
    }
    .fl-xl-g-3{
        flex-grow:3!important
    }
    .fl-xl-g-4{
        flex-grow:4!important
    }
    .fl-xl-g-5{
        flex-grow:5!important
    }
    .fl-xl-g-6{
        flex-grow:6!important
    }
    .fl-xl-g-7{
        flex-grow:7!important
    }
    .fl-xl-g-8{
        flex-grow:8!important
    }
    .fl-xl-g-9{
        flex-grow:9!important
    }
    .fl-xl-g-10{
        flex-grow:10!important
    }
    .fl-xl-g-11{
        flex-grow:11!important
    }
    .fl-xl-g-12{
        flex-grow:12!important
    }
    .fl-xl-w{
        flex-wrap:wrap!important
    }
    .fl-xl-nw{
        flex-wrap:nowrap!important
    }
    .fl-xl-col{
        flex-direction:column!important
    }
    .fl-xl-row{
        flex-direction:row!important
    }
    .ai-xl-s{
        align-items:flex-start!important
    }
    .ai-xl-c{
        align-items:center!important
    }
    .ai-xl-bl{
        align-items:baseline!important
    }
    .ai-xl-end{
        align-items:flex-end!important
    }
    .ai-xl-str{
        align-items:stretch!important
    }
    .jc-xl-s{
        justify-content:flex-start!important
    }
    .jc-xl-c{
        justify-content:center!important
    }
    .jc-xl-end{
        justify-content:flex-end!important
    }
    .jc-xl-sb{
        justify-content:space-between!important
    }
    .jc-xl-ev{
        justify-content:space-evenly!important
    }
    .jc-xl-ar{
        justify-content:space-around!important
    }
}
@media (min-width:1680px){
    .fl-xxl-sr-0{
        flex-shrink:0!important
    }
    .fl-xxl-sr-1{
        flex-shrink:1!important
    }
    .fl-xxl-g{
        flex-grow:1!important
    }
    .fl-xxl-g-1{
        flex-grow:1!important
    }
    .fl-xxl-g-2{
        flex-grow:2!important
    }
    .fl-xxl-g-3{
        flex-grow:3!important
    }
    .fl-xxl-g-4{
        flex-grow:4!important
    }
    .fl-xxl-g-5{
        flex-grow:5!important
    }
    .fl-xxl-g-6{
        flex-grow:6!important
    }
    .fl-xxl-g-7{
        flex-grow:7!important
    }
    .fl-xxl-g-8{
        flex-grow:8!important
    }
    .fl-xxl-g-9{
        flex-grow:9!important
    }
    .fl-xxl-g-10{
        flex-grow:10!important
    }
    .fl-xxl-g-11{
        flex-grow:11!important
    }
    .fl-xxl-g-12{
        flex-grow:12!important
    }
    .fl-xxl-w{
        flex-wrap:wrap!important
    }
    .fl-xxl-nw{
        flex-wrap:nowrap!important
    }
    .fl-xxl-col{
        flex-direction:column!important
    }
    .fl-xxl-row{
        flex-direction:row!important
    }
    .ai-xxl-s{
        align-items:flex-start!important
    }
    .ai-xxl-c{
        align-items:center!important
    }
    .ai-xxl-bl{
        align-items:baseline!important
    }
    .ai-xxl-end{
        align-items:flex-end!important
    }
    .ai-xxl-str{
        align-items:stretch!important
    }
    .jc-xxl-s{
        justify-content:flex-start!important
    }
    .jc-xxl-c{
        justify-content:center!important
    }
    .jc-xxl-end{
        justify-content:flex-end!important
    }
    .jc-xxl-sb{
        justify-content:space-between!important
    }
    .jc-xxl-ev{
        justify-content:space-evenly!important
    }
    .jc-xxl-ar{
        justify-content:space-around!important
    }
}
.cg-4{
    column-gap:4px
}
.cg-8{
    column-gap:8px
}
.cg-12{
    column-gap:12px
}
.cg-16{
    column-gap:16px
}
.cg-24{
    column-gap:24px
}
.cg-32{
    column-gap:32px
}
.cg-40{
    column-gap:40px
}
.cg-48{
    column-gap:48px
}
.rg-4{
    row-gap:4px
}
.rg-8{
    row-gap:8px
}
.rg-12{
    row-gap:12px
}
.rg-16{
    row-gap:16px
}
.rg-24{
    row-gap:24px
}
.rg-32{
    row-gap:32px
}
.rg-40{
    row-gap:40px
}
.rg-48{
    row-gap:48px
}
@media (min-width:370px){
    .cg-3xs-0{
        column-gap:0
    }
    .cg-3xs-8{
        column-gap:8px
    }
    .cg-3xs-12{
        column-gap:12px
    }
    .cg-3xs-16{
        column-gap:16px
    }
    .cg-3xs-24{
        column-gap:24px
    }
    .cg-3xs-32{
        column-gap:32px
    }
    .cg-3xs-40{
        column-gap:40px
    }
    .cg-3xs-48{
        column-gap:48px
    }
    .rg-3xs-0{
        row-gap:0
    }
    .rg-3xs-8{
        row-gap:8px
    }
    .rg-3xs-12{
        row-gap:12px
    }
    .rg-3xs-16{
        row-gap:16px
    }
    .rg-3xs-24{
        row-gap:24px
    }
    .rg-3xs-32{
        row-gap:32px
    }
    .rg-3xs-40{
        row-gap:40px
    }
    .rg-3xs-48{
        row-gap:48px
    }
}
@media (min-width:480px){
    .cg-2xs-0{
        column-gap:0
    }
    .cg-2xs-8{
        column-gap:8px
    }
    .cg-2xs-12{
        column-gap:12px
    }
    .cg-2xs-16{
        column-gap:16px
    }
    .cg-2xs-24{
        column-gap:24px
    }
    .cg-2xs-32{
        column-gap:32px
    }
    .cg-2xs-40{
        column-gap:40px
    }
    .cg-2xs-48{
        column-gap:48px
    }
    .rg-2xs-0{
        row-gap:0
    }
    .rg-2xs-8{
        row-gap:8px
    }
    .rg-2xs-12{
        row-gap:12px
    }
    .rg-2xs-16{
        row-gap:16px
    }
    .rg-2xs-24{
        row-gap:24px
    }
    .rg-2xs-32{
        row-gap:32px
    }
    .rg-2xs-40{
        row-gap:40px
    }
    .rg-2xs-48{
        row-gap:48px
    }
}
@media (min-width:640px){
    .cg-xs-0{
        column-gap:0
    }
    .cg-xs-8{
        column-gap:8px
    }
    .cg-xs-12{
        column-gap:12px
    }
    .cg-xs-16{
        column-gap:16px
    }
    .cg-xs-24{
        column-gap:24px
    }
    .cg-xs-32{
        column-gap:32px
    }
    .cg-xs-40{
        column-gap:40px
    }
    .cg-xs-48{
        column-gap:48px
    }
    .rg-xs-0{
        row-gap:0
    }
    .rg-xs-8{
        row-gap:8px
    }
    .rg-xs-12{
        row-gap:12px
    }
    .rg-xs-16{
        row-gap:16px
    }
    .rg-xs-24{
        row-gap:24px
    }
    .rg-xs-32{
        row-gap:32px
    }
    .rg-xs-40{
        row-gap:40px
    }
    .rg-xs-48{
        row-gap:48px
    }
}
@media (min-width:768px){
    .cg-sm-0{
        column-gap:0
    }
    .cg-sm-8{
        column-gap:8px
    }
    .cg-sm-12{
        column-gap:12px
    }
    .cg-sm-16{
        column-gap:16px
    }
    .cg-sm-24{
        column-gap:24px
    }
    .cg-sm-32{
        column-gap:32px
    }
    .cg-sm-40{
        column-gap:40px
    }
    .cg-sm-48{
        column-gap:48px
    }
    .rg-sm-8{
        row-gap:0
    }
    .rg-sm-8{
        row-gap:8px
    }
    .rg-sm-12{
        row-gap:12px
    }
    .rg-sm-16{
        row-gap:16px
    }
    .rg-sm-24{
        row-gap:24px
    }
    .rg-sm-32{
        row-gap:32px
    }
    .rg-sm-40{
        row-gap:40px
    }
    .rg-sm-48{
        row-gap:48px
    }
}
@media (min-width:1024px){
    .cg-md-0{
        column-gap:0
    }
    .cg-md-8{
        column-gap:8px
    }
    .cg-md-12{
        column-gap:12px
    }
    .cg-md-16{
        column-gap:16px
    }
    .cg-md-24{
        column-gap:24px
    }
    .cg-md-32{
        column-gap:32px
    }
    .cg-md-40{
        column-gap:40px
    }
    .cg-md-48{
        column-gap:48px
    }
    .rg-md-0{
        row-gap:0
    }
    .rg-md-8{
        row-gap:8px
    }
    .rg-md-12{
        row-gap:12px
    }
    .rg-md-16{
        row-gap:16px
    }
    .rg-md-24{
        row-gap:24px
    }
    .rg-md-32{
        row-gap:32px
    }
    .rg-md-40{
        row-gap:40px
    }
    .rg-md-48{
        row-gap:48px
    }
}
@media (min-width:1280px){
    .cg-lg-0{
        column-gap:0
    }
    .cg-lg-8{
        column-gap:8px
    }
    .cg-lg-12{
        column-gap:12px
    }
    .cg-lg-16{
        column-gap:16px
    }
    .cg-lg-24{
        column-gap:24px
    }
    .cg-lg-32{
        column-gap:32px
    }
    .cg-lg-40{
        column-gap:40px
    }
    .cg-lg-48{
        column-gap:48px
    }
    .rg-lg-0{
        row-gap:0
    }
    .rg-lg-8{
        row-gap:8px
    }
    .rg-lg-12{
        row-gap:12px
    }
    .rg-lg-16{
        row-gap:16px
    }
    .rg-lg-24{
        row-gap:24px
    }
    .rg-lg-32{
        row-gap:32px
    }
    .rg-lg-40{
        row-gap:40px
    }
    .rg-lg-48{
        row-gap:48px
    }
}
@media (min-width:1360px){
    .cg-xl-0{
        column-gap:0
    }
    .cg-xl-8{
        column-gap:8px
    }
    .cg-xl-12{
        column-gap:12px
    }
    .cg-xl-16{
        column-gap:16px
    }
    .cg-xl-24{
        column-gap:24px
    }
    .cg-xl-32{
        column-gap:32px
    }
    .cg-xl-40{
        column-gap:40px
    }
    .cg-xl-48{
        column-gap:48px
    }
    .rg-xl-0{
        row-gap:0
    }
    .rg-xl-8{
        row-gap:8px
    }
    .rg-xl-12{
        row-gap:12px
    }
    .rg-xl-16{
        row-gap:16px
    }
    .rg-xl-24{
        row-gap:24px
    }
    .rg-xl-32{
        row-gap:32px
    }
    .rg-xl-40{
        row-gap:40px
    }
    .rg-xl-48{
        row-gap:48px
    }
}
@media (min-width:1680px){
    .cg-xxl-0{
        column-gap:0
    }
    .cg-xxl-8{
        column-gap:8px
    }
    .cg-xxl-12{
        column-gap:12px
    }
    .cg-xxl-16{
        column-gap:16px
    }
    .cg-xxl-24{
        column-gap:24px
    }
    .cg-xxl-32{
        column-gap:32px
    }
    .cg-xxl-40{
        column-gap:40px
    }
    .cg-xxl-48{
        column-gap:48px
    }
    .rg-xxl-0{
        row-gap:0
    }
    .rg-xxl-8{
        row-gap:8px
    }
    .rg-xxl-12{
        row-gap:12px
    }
    .rg-xxl-16{
        row-gap:16px
    }
    .rg-xxl-24{
        row-gap:24px
    }
    .rg-xxl-32{
        row-gap:32px
    }
    .rg-xxl-40{
        row-gap:40px
    }
    .rg-xxl-48{
        row-gap:48px
    }
}
.fl-o-1{
    order:1!important
}
.fl-o-2{
    order:2!important
}
.fl-o-3{
    order:3!important
}
.fl-o-4{
    order:4!important
}
.fl-o-5{
    order:5!important
}
.fl-o-6{
    order:6!important
}
.fl-o-7{
    order:7!important
}
.fl-o-8{
    order:8!important
}
.fl-o-9{
    order:9!important
}
.fl-o-10{
    order:10!important
}
.fl-o-11{
    order:11!important
}
.fl-o-12{
    order:12!important
}
.fl-o-99{
    order:99!important
}
@media (min-width:370px){
    .fl-3xs-o-1{
        order:1!important
    }
    .fl-3xs-o-2{
        order:2!important
    }
    .fl-3xs-o-3{
        order:3!important
    }
    .fl-3xs-o-4{
        order:4!important
    }
    .fl-3xs-o-5{
        order:5!important
    }
    .fl-3xs-o-6{
        order:6!important
    }
    .fl-3xs-o-7{
        order:7!important
    }
    .fl-3xs-o-8{
        order:8!important
    }
    .fl-3xs-o-9{
        order:9!important
    }
    .fl-3xs-o-10{
        order:10!important
    }
    .fl-3xs-o-11{
        order:11!important
    }
    .fl-3xs-o-12{
        order:12!important
    }
}
@media (min-width:480px){
    .fl-2xs-o-1{
        order:1!important
    }
    .fl-2xs-o-2{
        order:2!important
    }
    .fl-2xs-o-3{
        order:3!important
    }
    .fl-2xs-o-4{
        order:4!important
    }
    .fl-2xs-o-5{
        order:5!important
    }
    .fl-2xs-o-6{
        order:6!important
    }
    .fl-2xs-o-7{
        order:7!important
    }
    .fl-2xs-o-8{
        order:8!important
    }
    .fl-2xs-o-9{
        order:9!important
    }
    .fl-2xs-o-10{
        order:10!important
    }
    .fl-2xs-o-11{
        order:11!important
    }
    .fl-2xs-o-12{
        order:12!important
    }
}
@media (min-width:640px){
    .fl-xs-o-1{
        order:1!important
    }
    .fl-xs-o-2{
        order:2!important
    }
    .fl-xs-o-3{
        order:3!important
    }
    .fl-xs-o-4{
        order:4!important
    }
    .fl-xs-o-5{
        order:5!important
    }
    .fl-xs-o-6{
        order:6!important
    }
    .fl-xs-o-7{
        order:7!important
    }
    .fl-xs-o-8{
        order:8!important
    }
    .fl-xs-o-9{
        order:9!important
    }
    .fl-xs-o-10{
        order:10!important
    }
    .fl-xs-o-11{
        order:11!important
    }
    .fl-xs-o-12{
        order:12!important
    }
}
@media (min-width:768px){
    .fl-sm-o-1{
        order:1!important
    }
    .fl-sm-o-2{
        order:2!important
    }
    .fl-sm-o-3{
        order:3!important
    }
    .fl-sm-o-4{
        order:4!important
    }
    .fl-sm-o-5{
        order:5!important
    }
    .fl-sm-o-6{
        order:6!important
    }
    .fl-sm-o-7{
        order:7!important
    }
    .fl-sm-o-8{
        order:8!important
    }
    .fl-sm-o-9{
        order:9!important
    }
    .fl-sm-o-10{
        order:10!important
    }
    .fl-sm-o-11{
        order:11!important
    }
    .fl-sm-o-12{
        order:12!important
    }
}
@media (min-width:1024px){
    .fl-md-o-1{
        order:1!important
    }
    .fl-md-o-2{
        order:2!important
    }
    .fl-md-o-3{
        order:3!important
    }
    .fl-md-o-4{
        order:4!important
    }
    .fl-md-o-5{
        order:5!important
    }
    .fl-md-o-6{
        order:6!important
    }
    .fl-md-o-7{
        order:7!important
    }
    .fl-md-o-8{
        order:8!important
    }
    .fl-md-o-9{
        order:9!important
    }
    .fl-md-o-10{
        order:10!important
    }
    .fl-md-o-11{
        order:11!important
    }
    .fl-md-o-12{
        order:12!important
    }
}
@media (min-width:1280px){
    .fl-lg-o-1{
        order:1!important
    }
    .fl-lg-o-2{
        order:2!important
    }
    .fl-lg-o-3{
        order:3!important
    }
    .fl-lg-o-4{
        order:4!important
    }
    .fl-lg-o-5{
        order:5!important
    }
    .fl-lg-o-6{
        order:6!important
    }
    .fl-lg-o-7{
        order:7!important
    }
    .fl-lg-o-8{
        order:8!important
    }
    .fl-lg-o-9{
        order:9!important
    }
    .fl-lg-o-10{
        order:10!important
    }
    .fl-lg-o-11{
        order:11!important
    }
    .fl-lg-o-12{
        order:12!important
    }
}
@media (min-width:1360px){
    .fl-xl-o-1{
        order:1!important
    }
    .fl-xl-o-2{
        order:2!important
    }
    .fl-xl-o-3{
        order:3!important
    }
    .fl-xl-o-4{
        order:4!important
    }
    .fl-xl-o-5{
        order:5!important
    }
    .fl-xl-o-6{
        order:6!important
    }
    .fl-xl-o-7{
        order:7!important
    }
    .fl-xl-o-8{
        order:8!important
    }
    .fl-xl-o-9{
        order:9!important
    }
    .fl-xl-o-10{
        order:10!important
    }
    .fl-xl-o-11{
        order:11!important
    }
    .fl-xl-o-12{
        order:12!important
    }
}
@media (min-width:1680px){
    .fl-xxl-o-1{
        order:1!important
    }
    .fl-xxl-o-2{
        order:2!important
    }
    .fl-xxl-o-3{
        order:3!important
    }
    .fl-xxl-o-4{
        order:4!important
    }
    .fl-xxl-o-5{
        order:5!important
    }
    .fl-xxl-o-6{
        order:6!important
    }
    .fl-xxl-o-7{
        order:7!important
    }
    .fl-xxl-o-8{
        order:8!important
    }
    .fl-xxl-o-9{
        order:9!important
    }
    .fl-xxl-o-10{
        order:10!important
    }
    .fl-xxl-o-11{
        order:11!important
    }
    .fl-xxl-o-12{
        order:12!important
    }
}
.js-scroll{
    opacity:0;
    transition:opacity .5s
}
.js-scroll.scrolled{
    opacity:1
}
.scroll-main.fade-in,.scrolled.fade-in{
    animation:fade-in ease-in-out both
}
.scroll-main .fade-in-bottom,.scroll-main.fade-in-bottom,.scrolled .fade-in-bottom,.scrolled.fade-in-bottom{
    animation:fade-in-bottom ease-in-out both
}
.scroll-main.fade-in-bottom-small,.scrolled.fade-in-bottom-small{
    animation:fade-in-bottom-small ease-in-out both
}
.scroll-main.fade-in-top,.scrolled.fade-in-top{
    animation:fade-in-top ease-in-out both
}
.scroll-main.fade-in-top-small,.scrolled.fade-in-top-small{
    animation:fade-in-top-small ease-in-out both
}
.scroll-main.slide-left,.scrolled.slide-left{
    animation:slide-in-left ease-in-out both
}
.scroll-main.slide-left-small,.scrolled.slide-left-small{
    animation:slide-in-left-small ease-in-out both
}
.scroll-main.slide-right,.scrolled.slide-right{
    animation:slide-in-right ease-in-out both
}
.scroll-main.slide-right-small,.scrolled.slide-right-small{
    animation:slide-in-right-small ease-in-out both
}
.scroll-main.render,.scrolled.render{
    animation-name:render
}
.scroll-main.a-dl-01,.scrolled.a-dl-01{
    animation-delay:.1s
}
.scroll-main.a-dl-02,.scrolled.a-dl-02{
    animation-delay:.2s
}
.scroll-main.a-dl-03,.scrolled.a-dl-03{
    animation-delay:.3s
}
.scroll-main.a-dl-04,.scrolled.a-dl-04{
    animation-delay:.4s
}
.scroll-main.a-dl-05,.scrolled.a-dl-05{
    animation-delay:.5s
}
.scroll-main.a-dl-06,.scrolled.a-dl-06{
    animation-delay:.6s
}
.scroll-main.a-dl-07,.scrolled.a-dl-07{
    animation-delay:.7s
}
.scroll-main.a-dl-08,.scrolled.a-dl-08{
    animation-delay:.8s
}
.scroll-main.a-dl-09,.scrolled.a-dl-09{
    animation-delay:.9s
}
.scroll-main.a-dl-1,.scrolled.a-dl-1{
    animation-delay:1s
}
.scroll-main.a-dl-1-1,.scrolled.a-dl-1-1{
    animation-delay:1.1s
}
.scroll-main.a-dl-1-2,.scrolled.a-dl-1-2{
    animation-delay:1.2s
}
.scroll-main.a-dl-1-3,.scrolled.a-dl-1-3{
    animation-delay:1.3s
}
.scroll-main.a-dl-1-4,.scrolled.a-dl-1-4{
    animation-delay:1.4s
}
.scroll-main.a-dl-1-5,.scrolled.a-dl-1-5{
    animation-delay:1.5s
}
.scroll-main.a-dl-1-6,.scrolled.a-dl-1-6{
    animation-delay:1.6s
}
.scroll-main.a-dl-1-7,.scrolled.a-dl-1-7{
    animation-delay:1.7s
}
.scroll-main.a-dl-1-8,.scrolled.a-dl-1-8{
    animation-delay:1.8s
}
.scroll-main.a-dl-1-9,.scrolled.a-dl-1-9{
    animation-delay:1.9s
}
.scroll-main.a-dr-01,.scrolled.a-dr-01{
    animation-duration:.1s
}
.scroll-main.a-dr-02,.scrolled.a-dr-02{
    animation-duration:.2s
}
.scroll-main.a-dr-03,.scrolled.a-dr-03{
    animation-duration:.3s
}
.scroll-main.a-dr-04,.scrolled.a-dr-04{
    animation-duration:.4s
}
.scroll-main.a-dr-05,.scrolled.a-dr-05{
    animation-duration:.5s
}
.scroll-main.a-dr-06,.scrolled.a-dr-06{
    animation-duration:.6s
}
.scroll-main.a-dr-07,.scrolled.a-dr-07{
    animation-duration:.7s
}
.scroll-main.a-dr-08,.scrolled.a-dr-08{
    animation-duration:.8s
}
.scroll-main.a-dr-09,.scrolled.a-dr-09{
    animation-duration:.9s
}
.scroll-main.a-dr-1-1,.scrolled.a-dr-1-1{
    animation-duration:1s
}
.scroll-main.a-dr-1-2,.scrolled.a-dr-1-2{
    animation-duration:1.2s
}
.scroll-main.a-dr-1-3,.scrolled.a-dr-1-3{
    animation-duration:1.3s
}
.scroll-main.a-dr-1-4,.scrolled.a-dr-1-4{
    animation-duration:1.4s
}
.scroll-main.a-dr-1-5,.scrolled.a-dr-1-5{
    animation-duration:1.5s
}
.scroll-main.a-dr-1-6,.scrolled.a-dr-1-6{
    animation-duration:1.6s
}
.scroll-main.a-dr-1-7,.scrolled.a-dr-1-7{
    animation-duration:1.7s
}
.scroll-main.a-dr-1-8,.scrolled.a-dr-1-8{
    animation-duration:1.8s
}
.scroll-main.a-dr-1-9,.scrolled.a-dr-1-9{
    animation-duration:1.9s
}
@keyframes render{
    0%{
        transform:scale(1)
    }
    50%{
        transform:scale(1.3)
    }
    to{
        transform:scale(1)
    }
}
@keyframes slide-in-left{
    0%{
        -webkit-transform:translateX(-50px);
        transform:translateX(-50px);
        opacity:0
    }
    to{
        -webkit-transform:translateX(0);
        transform:translateX(0);
        opacity:1
    }
}
@keyframes slide-in-left-small{
    0%{
        -webkit-transform:translateX(-20px);
        transform:translateX(-20px);
        opacity:0
    }
    to{
        -webkit-transform:translateX(0);
        transform:translateX(0);
        opacity:1
    }
}
@keyframes slide-in-right{
    0%{
        -webkit-transform:translateX(50px);
        transform:translateX(50px);
        opacity:0
    }
    to{
        -webkit-transform:translateX(0);
        transform:translateX(0);
        opacity:1
    }
}
@keyframes slide-in-right-small{
    0%{
        -webkit-transform:translateX(40px);
        transform:translateX(40px);
        opacity:0
    }
    to{
        -webkit-transform:translateX(0);
        transform:translateX(0);
        opacity:1
    }
}
@keyframes fade-in-top{
    0%{
        -webkit-transform:translateY(-40px);
        transform:translateY(-40px);
        opacity:0
    }
    to{
        -webkit-transform:translateY(0);
        transform:translateY(0);
        opacity:1
    }
}
@keyframes fade-in-top-small{
    0%{
        -webkit-transform:translateY(-20px);
        transform:translateY(-20px);
        opacity:0
    }
    to{
        -webkit-transform:translateY(0);
        transform:translateY(0);
        opacity:1
    }
}
@keyframes fade-in-bottom{
    0%{
        -webkit-transform:translateY(40px);
        transform:translateY(40px);
        opacity:0
    }
    to{
        -webkit-transform:translateY(0);
        transform:translateY(0);
        opacity:1
    }
}
@keyframes fade-in-bottom-small{
    0%{
        -webkit-transform:translateY(20px);
        transform:translateY(20px);
        opacity:0
    }
    to{
        -webkit-transform:translateY(0);
        transform:translateY(0);
        opacity:1
    }
}
@keyframes fade-in{
    0%{
        opacity:0
    }
    to{
        opacity:1
    }
}
.m-0,.mt-0,.my-0{
    margin-top:0!important
}
.m-4,.mt-4,.my-4{
    margin-top:4px!important
}
.m-8,.mt-8,.my-8{
    margin-top:8px!important
}
.m-12,.mt-12,.my-12{
    margin-top:12px!important
}
.m-16,.mt-16,.my-16{
    margin-top:16px!important
}
.m-20,.mt-20,.my-20{
    margin-top:20px!important
}
.m-24,.mt-24,.my-24{
    margin-top:24px!important
}
.m-24,.mt-28,.my-28{
    margin-top:28px!important
}
.m-32,.mt-32,.my-32{
    margin-top:32px!important
}
.m-36,.mt-36,.my-36{
    margin-top:36px!important
}
.m-40,.mt-40,.my-40{
    margin-top:40px!important
}
.m-44,.mt-44,.my-44{
    margin-top:44px!important
}
.m-48,.mt-48,.my-48{
    margin-top:48px!important
}
.m-60,.mt-60,.my-60{
    margin-top:60px!important
}
.m-80,.mt-80,.my-80{
    margin-top:80px!important
}
.m-120,.mt-120,.my-120{
    margin-top:120px!important
}
.m-0,.mb-0,.my-0{
    margin-bottom:0!important
}
.m-4,.mb-4,.my-4{
    margin-bottom:4px!important
}
.m-8,.mb-8,.my-8{
    margin-bottom:8px!important
}
.m-12,.mb-12,.my-12{
    margin-bottom:12px!important
}
.m-16,.mb-16,.my-16{
    margin-bottom:16px!important
}
.m-20,.mb-20,.my-20{
    margin-bottom:20px!important
}
.m-24,.mb-24,.my-24{
    margin-bottom:24px!important
}
.m-24,.mb-28,.my-28{
    margin-bottom:28px!important
}
.m-32,.mb-32,.my-32{
    margin-bottom:32px!important
}
.m-36,.mb-36,.my-36{
    margin-bottom:36px!important
}
.m-40,.mb-40,.my-40{
    margin-bottom:40px!important
}
.m-44,.mb-44,.my-44{
    margin-bottom:44px!important
}
.m-48,.mb-48,.my-48{
    margin-bottom:48px!important
}
.m-60,.mb-60,.my-60{
    margin-bottom:60px!important
}
.m-80,.mb-80,.my-80{
    margin-bottom:80px!important
}
.m-120,.mb-120,.my-120{
    margin-bottom:120px!important
}
.m-0,.ml-0,.mx-0{
    margin-left:0!important
}
.m-4,.ml-4,.mx-4{
    margin-left:4px!important
}
.m-8,.ml-8,.mx-8{
    margin-left:8px!important
}
.m-12,.ml-12,.mx-12{
    margin-left:12px!important
}
.m-16,.ml-16,.mx-16{
    margin-left:16px!important
}
.m-20,.ml-20,.mx-20{
    margin-left:20px!important
}
.m-24,.ml-24,.mx-24{
    margin-left:24px!important
}
.m-24,.ml-28,.mx-28{
    margin-left:28px!important
}
.m-32,.ml-32,.mx-32{
    margin-left:32px!important
}
.m-36,.ml-36,.mx-36{
    margin-left:36px!important
}
.m-40,.ml-40,.mx-40{
    margin-left:40px!important
}
.m-44,.ml-44,.mx-44{
    margin-left:44px!important
}
.m-48,.ml-48,.mx-48{
    margin-left:48px!important
}
.m-60,.ml-60,.mx-60{
    margin-left:60px!important
}
.m-80,.ml-80,.mx-80{
    margin-left:80px!important
}
.m-120,.ml-120,.mx-120{
    margin-left:120px!important
}
.m-0,.mr-0,.mx-0{
    margin-right:0!important
}
.m-4,.mr-4,.mx-4{
    margin-right:4px!important
}
.m-8,.mr-8,.mx-8{
    margin-right:8px!important
}
.m-12,.mr-12,.mx-12{
    margin-right:12px!important
}
.m-16,.mr-16,.mx-16{
    margin-right:16px!important
}
.m-20,.mr-20,.mx-20{
    margin-right:20px!important
}
.m-24,.mr-24,.mx-24{
    margin-right:24px!important
}
.m-24,.mr-28,.mx-28{
    margin-right:28px!important
}
.m-32,.mr-32,.mx-32{
    margin-right:32px!important
}
.m-36,.mr-36,.mx-36{
    margin-right:36px!important
}
.m-40,.mr-40,.mx-40{
    margin-right:40px!important
}
.m-44,.mr-44,.mx-44{
    margin-right:44px!important
}
.m-48,.mr-48,.mx-48{
    margin-right:48px!important
}
.m-60,.mr-60,.mx-60{
    margin-right:60px!important
}
.m-80,.mr-80,.mx-80{
    margin-right:80px!important
}
.m-120,.mr-120,.mx-120{
    margin-right:120px!important
}
@media (min-width:370px){
    .m-3xs-0,.mt-3xs-0,.my-3xs-0{
        margin-top:0!important
    }
    .m-3xs-4,.mt-3xs-4,.my-3xs-4{
        margin-top:4px!important
    }
    .m-3xs-8,.mt-3xs-8,.my-3xs-8{
        margin-top:8px!important
    }
    .m-3xs-12,.mt-3xs-12,.my-3xs-12{
        margin-top:12px!important
    }
    .m-3xs-16,.mt-3xs-16,.my-3xs-16{
        margin-top:16px!important
    }
    .m-3xs-20,.mt-3xs-20,.my-3xs-20{
        margin-top:20px!important
    }
    .m-3xs-24,.mt-3xs-24,.my-3xs-24{
        margin-top:24px!important
    }
    .m-3xs-24,.mt-3xs-28,.my-3xs-28{
        margin-top:28px!important
    }
    .m-3xs-32,.mt-3xs-32,.my-3xs-32{
        margin-top:32px!important
    }
    .m-3xs-36,.mt-3xs-36,.my-3xs-36{
        margin-top:36px!important
    }
    .m-3xs-40,.mt-3xs-40,.my-3xs-40{
        margin-top:40px!important
    }
    .m-3xs-44,.mt-3xs-44,.my-3xs-44{
        margin-top:44px!important
    }
    .m-3xs-48,.mt-3xs-48,.my-3xs-48{
        margin-top:48px!important
    }
    .m-3xs-60,.mt-3xs-60,.my-3xs-60{
        margin-top:60px!important
    }
    .m-3xs-80,.mt-3xs-80,.my-3xs-80{
        margin-top:80px!important
    }
    .m-3xs-120,.mt-3xs-120,.my-3xs-120{
        margin-top:120px!important
    }
    .m-3xs-0,.mb-3xs-0,.my-3xs-0{
        margin-bottom:0!important
    }
    .m-3xs-4,.mb-3xs-4,.my-3xs-4{
        margin-bottom:4px!important
    }
    .m-3xs-8,.mb-3xs-8,.my-3xs-8{
        margin-bottom:8px!important
    }
    .m-3xs-12,.mb-3xs-12,.my-3xs-12{
        margin-bottom:12px!important
    }
    .m-3xs-16,.mb-3xs-16,.my-3xs-16{
        margin-bottom:16px!important
    }
    .m-3xs-20,.mb-3xs-20,.my-3xs-20{
        margin-bottom:20px!important
    }
    .m-3xs-24,.mb-3xs-24,.my-3xs-24{
        margin-bottom:24px!important
    }
    .m-3xs-24,.mb-3xs-28,.my-3xs-28{
        margin-bottom:28px!important
    }
    .m-3xs-32,.mb-3xs-32,.my-3xs-32{
        margin-bottom:32px!important
    }
    .m-3xs-36,.mb-3xs-36,.my-3xs-36{
        margin-bottom:36px!important
    }
    .m-3xs-40,.mb-3xs-40,.my-3xs-40{
        margin-bottom:40px!important
    }
    .m-3xs-44,.mb-3xs-44,.my-3xs-44{
        margin-bottom:44px!important
    }
    .m-3xs-48,.mb-3xs-48,.my-3xs-48{
        margin-bottom:48px!important
    }
    .m-3xs-60,.mb-3xs-60,.my-3xs-60{
        margin-bottom:60px!important
    }
    .m-3xs-80,.mb-3xs-80,.my-3xs-80{
        margin-bottom:80px!important
    }
    .m-3xs-120,.mb-3xs-120,.my-3xs-120{
        margin-bottom:120px!important
    }
    .m-3xs-0,.ml-3xs-0,.mx-3xs-0{
        margin-left:0!important
    }
    .m-3xs-4,.ml-3xs-4,.mx-3xs-4{
        margin-left:4px!important
    }
    .m-3xs-8,.ml-3xs-8,.mx-3xs-8{
        margin-left:8px!important
    }
    .m-3xs-12,.ml-3xs-12,.mx-3xs-12{
        margin-left:12px!important
    }
    .m-3xs-16,.ml-3xs-16,.mx-3xs-16{
        margin-left:16px!important
    }
    .m-3xs-20,.ml-3xs-20,.mx-3xs-20{
        margin-left:20px!important
    }
    .m-3xs-24,.ml-3xs-24,.mx-3xs-24{
        margin-left:24px!important
    }
    .m-3xs-24,.ml-3xs-28,.mx-3xs-28{
        margin-left:28px!important
    }
    .m-3xs-32,.ml-3xs-32,.mx-3xs-32{
        margin-left:32px!important
    }
    .m-3xs-36,.ml-3xs-36,.mx-3xs-36{
        margin-left:36px!important
    }
    .m-3xs-40,.ml-3xs-40,.mx-3xs-40{
        margin-left:40px!important
    }
    .m-3xs-44,.ml-3xs-44,.mx-3xs-44{
        margin-left:44px!important
    }
    .m-3xs-48,.ml-3xs-48,.mx-3xs-48{
        margin-left:48px!important
    }
    .m-3xs-60,.ml-3xs-60,.mx-3xs-60{
        margin-left:60px!important
    }
    .m-3xs-80,.ml-3xs-80,.mx-3xs-80{
        margin-left:80px!important
    }
    .m-3xs-120,.ml-3xs-120,.mx-3xs-120{
        margin-left:120px!important
    }
    .m-3xs-0,.mr-3xs-0,.mx-3xs-0{
        margin-right:0!important
    }
    .m-3xs-4,.mr-3xs-4,.mx-3xs-4{
        margin-right:4px!important
    }
    .m-3xs-8,.mr-3xs-8,.mx-3xs-8{
        margin-right:8px!important
    }
    .m-3xs-12,.mr-3xs-12,.mx-3xs-12{
        margin-right:12px!important
    }
    .m-3xs-16,.mr-3xs-16,.mx-3xs-16{
        margin-right:16px!important
    }
    .m-3xs-20,.mr-3xs-20,.mx-3xs-20{
        margin-right:20px!important
    }
    .m-3xs-24,.mr-3xs-24,.mx-3xs-24{
        margin-right:24px!important
    }
    .m-3xs-24,.mr-3xs-28,.mx-3xs-28{
        margin-right:28px!important
    }
    .m-3xs-32,.mr-3xs-32,.mx-3xs-32{
        margin-right:32px!important
    }
    .m-3xs-36,.mr-3xs-36,.mx-3xs-36{
        margin-right:36px!important
    }
    .m-3xs-40,.mr-3xs-40,.mx-3xs-40{
        margin-right:40px!important
    }
    .m-3xs-44,.mr-3xs-44,.mx-3xs-44{
        margin-right:44px!important
    }
    .m-3xs-48,.mr-3xs-48,.mx-3xs-48{
        margin-right:48px!important
    }
    .m-3xs-60,.mr-3xs-60,.mx-3xs-60{
        margin-right:60px!important
    }
    .m-3xs-80,.mr-3xs-80,.mx-3xs-80{
        margin-right:80px!important
    }
    .m-3xs-120,.mr-3xs-120,.mx-3xs-120{
        margin-right:120px!important
    }
}
@media (min-width:480px){
    .m-2xs-0,.mt-2xs-0,.my-2xs-0{
        margin-top:0!important
    }
    .m-2xs-4,.mt-2xs-4,.my-2xs-4{
        margin-top:4px!important
    }
    .m-2xs-8,.mt-2xs-8,.my-2xs-8{
        margin-top:8px!important
    }
    .m-2xs-12,.mt-2xs-12,.my-2xs-12{
        margin-top:12px!important
    }
    .m-2xs-16,.mt-2xs-16,.my-2xs-16{
        margin-top:16px!important
    }
    .m-2xs-20,.mt-2xs-20,.my-2xs-20{
        margin-top:20px!important
    }
    .m-2xs-24,.mt-2xs-24,.my-2xs-24{
        margin-top:24px!important
    }
    .m-2xs-24,.mt-2xs-28,.my-2xs-28{
        margin-top:28px!important
    }
    .m-2xs-32,.mt-2xs-32,.my-2xs-32{
        margin-top:32px!important
    }
    .m-2xs-36,.mt-2xs-36,.my-2xs-36{
        margin-top:36px!important
    }
    .m-2xs-40,.mt-2xs-40,.my-2xs-40{
        margin-top:40px!important
    }
    .m-2xs-44,.mt-2xs-44,.my-2xs-44{
        margin-top:44px!important
    }
    .m-2xs-48,.mt-2xs-48,.my-2xs-48{
        margin-top:48px!important
    }
    .m-2xs-60,.mt-2xs-60,.my-2xs-60{
        margin-top:60px!important
    }
    .m-2xs-80,.mt-2xs-80,.my-2xs-80{
        margin-top:80px!important
    }
    .m-2xs-120,.mt-2xs-120,.my-2xs-120{
        margin-top:120px!important
    }
    .m-2xs-0,.mb-2xs-0,.my-2xs-0{
        margin-bottom:0!important
    }
    .m-2xs-4,.mb-2xs-4,.my-2xs-4{
        margin-bottom:4px!important
    }
    .m-2xs-8,.mb-2xs-8,.my-2xs-8{
        margin-bottom:8px!important
    }
    .m-2xs-12,.mb-2xs-12,.my-2xs-12{
        margin-bottom:12px!important
    }
    .m-2xs-16,.mb-2xs-16,.my-2xs-16{
        margin-bottom:16px!important
    }
    .m-2xs-20,.mb-2xs-20,.my-2xs-20{
        margin-bottom:20px!important
    }
    .m-2xs-24,.mb-2xs-24,.my-2xs-24{
        margin-bottom:24px!important
    }
    .m-2xs-24,.mb-2xs-28,.my-2xs-28{
        margin-bottom:28px!important
    }
    .m-2xs-32,.mb-2xs-32,.my-2xs-32{
        margin-bottom:32px!important
    }
    .m-2xs-36,.mb-2xs-36,.my-2xs-36{
        margin-bottom:36px!important
    }
    .m-2xs-40,.mb-2xs-40,.my-2xs-40{
        margin-bottom:40px!important
    }
    .m-2xs-44,.mb-2xs-44,.my-2xs-44{
        margin-bottom:44px!important
    }
    .m-2xs-48,.mb-2xs-48,.my-2xs-48{
        margin-bottom:48px!important
    }
    .m-2xs-60,.mb-2xs-60,.my-2xs-60{
        margin-bottom:60px!important
    }
    .m-2xs-80,.mb-2xs-80,.my-2xs-80{
        margin-bottom:80px!important
    }
    .m-2xs-120,.mb-2xs-120,.my-2xs-120{
        margin-bottom:120px!important
    }
    .m-2xs-0,.ml-2xs-0,.mx-2xs-0{
        margin-left:0!important
    }
    .m-2xs-4,.ml-2xs-4,.mx-2xs-4{
        margin-left:4px!important
    }
    .m-2xs-8,.ml-2xs-8,.mx-2xs-8{
        margin-left:8px!important
    }
    .m-2xs-12,.ml-2xs-12,.mx-2xs-12{
        margin-left:12px!important
    }
    .m-2xs-16,.ml-2xs-16,.mx-2xs-16{
        margin-left:16px!important
    }
    .m-2xs-20,.ml-2xs-20,.mx-2xs-20{
        margin-left:20px!important
    }
    .m-2xs-24,.ml-2xs-24,.mx-2xs-24{
        margin-left:24px!important
    }
    .m-2xs-24,.ml-2xs-28,.mx-2xs-28{
        margin-left:28px!important
    }
    .m-2xs-32,.ml-2xs-32,.mx-2xs-32{
        margin-left:32px!important
    }
    .m-2xs-36,.ml-2xs-36,.mx-2xs-36{
        margin-left:36px!important
    }
    .m-2xs-40,.ml-2xs-40,.mx-2xs-40{
        margin-left:40px!important
    }
    .m-2xs-44,.ml-2xs-44,.mx-2xs-44{
        margin-left:44px!important
    }
    .m-2xs-48,.ml-2xs-48,.mx-2xs-48{
        margin-left:48px!important
    }
    .m-2xs-60,.ml-2xs-60,.mx-2xs-60{
        margin-left:60px!important
    }
    .m-2xs-80,.ml-2xs-80,.mx-2xs-80{
        margin-left:80px!important
    }
    .m-2xs-120,.ml-2xs-120,.mx-2xs-120{
        margin-left:120px!important
    }
    .m-2xs-0,.mr-2xs-0,.mx-2xs-0{
        margin-right:0!important
    }
    .m-2xs-4,.mr-2xs-4,.mx-2xs-4{
        margin-right:4px!important
    }
    .m-2xs-8,.mr-2xs-8,.mx-2xs-8{
        margin-right:8px!important
    }
    .m-2xs-12,.mr-2xs-12,.mx-2xs-12{
        margin-right:12px!important
    }
    .m-2xs-16,.mr-2xs-16,.mx-2xs-16{
        margin-right:16px!important
    }
    .m-2xs-20,.mr-2xs-20,.mx-2xs-20{
        margin-right:20px!important
    }
    .m-2xs-24,.mr-2xs-24,.mx-2xs-24{
        margin-right:24px!important
    }
    .m-2xs-24,.mr-2xs-28,.mx-2xs-28{
        margin-right:28px!important
    }
    .m-2xs-32,.mr-2xs-32,.mx-2xs-32{
        margin-right:32px!important
    }
    .m-2xs-36,.mr-2xs-36,.mx-2xs-36{
        margin-right:36px!important
    }
    .m-2xs-40,.mr-2xs-40,.mx-2xs-40{
        margin-right:40px!important
    }
    .m-2xs-44,.mr-2xs-44,.mx-2xs-44{
        margin-right:44px!important
    }
    .m-2xs-48,.mr-2xs-48,.mx-2xs-48{
        margin-right:48px!important
    }
    .m-2xs-60,.mr-2xs-60,.mx-2xs-60{
        margin-right:60px!important
    }
    .m-2xs-80,.mr-2xs-80,.mx-2xs-80{
        margin-right:80px!important
    }
    .m-2xs-120,.mr-2xs-120,.mx-2xs-120{
        margin-right:120px!important
    }
}
@media (min-width:640px){
    .m-xs-0,.mt-xs-0,.my-xs-0{
        margin-top:0!important
    }
    .m-xs-4,.mt-xs-4,.my-xs-4{
        margin-top:4px!important
    }
    .m-xs-8,.mt-xs-8,.my-xs-8{
        margin-top:8px!important
    }
    .m-xs-12,.mt-xs-12,.my-xs-12{
        margin-top:12px!important
    }
    .m-xs-16,.mt-xs-16,.my-xs-16{
        margin-top:16px!important
    }
    .m-xs-20,.mt-xs-20,.my-xs-20{
        margin-top:20px!important
    }
    .m-xs-24,.mt-xs-24,.my-xs-24{
        margin-top:24px!important
    }
    .m-xs-24,.mt-xs-28,.my-xs-28{
        margin-top:28px!important
    }
    .m-xs-32,.mt-xs-32,.my-xs-32{
        margin-top:32px!important
    }
    .m-xs-36,.mt-xs-36,.my-xs-36{
        margin-top:36px!important
    }
    .m-xs-40,.mt-xs-40,.my-xs-40{
        margin-top:40px!important
    }
    .m-xs-44,.mt-xs-44,.my-xs-44{
        margin-top:44px!important
    }
    .m-xs-48,.mt-xs-48,.my-xs-48{
        margin-top:48px!important
    }
    .m-xs-60,.mt-xs-60,.my-xs-60{
        margin-top:60px!important
    }
    .m-xs-80,.mt-xs-80,.my-xs-80{
        margin-top:80px!important
    }
    .m-xs-120,.mt-xs-120,.my-xs-120{
        margin-top:120px!important
    }
    .m-xs-0,.mb-xs-0,.my-xs-0{
        margin-bottom:0!important
    }
    .m-xs-4,.mb-xs-4,.my-xs-4{
        margin-bottom:4px!important
    }
    .m-xs-8,.mb-xs-8,.my-xs-8{
        margin-bottom:8px!important
    }
    .m-xs-12,.mb-xs-12,.my-xs-12{
        margin-bottom:12px!important
    }
    .m-xs-16,.mb-xs-16,.my-xs-16{
        margin-bottom:16px!important
    }
    .m-xs-20,.mb-xs-20,.my-xs-20{
        margin-bottom:20px!important
    }
    .m-xs-24,.mb-xs-24,.my-xs-24{
        margin-bottom:24px!important
    }
    .m-xs-24,.mb-xs-28,.my-xs-28{
        margin-bottom:28px!important
    }
    .m-xs-32,.mb-xs-32,.my-xs-32{
        margin-bottom:32px!important
    }
    .m-xs-36,.mb-xs-36,.my-xs-36{
        margin-bottom:36px!important
    }
    .m-xs-40,.mb-xs-40,.my-xs-40{
        margin-bottom:40px!important
    }
    .m-xs-44,.mb-xs-44,.my-xs-44{
        margin-bottom:44px!important
    }
    .m-xs-48,.mb-xs-48,.my-xs-48{
        margin-bottom:48px!important
    }
    .m-xs-60,.mb-xs-60,.my-xs-60{
        margin-bottom:60px!important
    }
    .m-xs-80,.mb-xs-80,.my-xs-80{
        margin-bottom:80px!important
    }
    .m-xs-120,.mb-xs-120,.my-xs-120{
        margin-bottom:120px!important
    }
    .m-xs-0,.ml-xs-0,.mx-xs-0{
        margin-left:0!important
    }
    .m-xs-4,.ml-xs-4,.mx-xs-4{
        margin-left:4px!important
    }
    .m-xs-8,.ml-xs-8,.mx-xs-8{
        margin-left:8px!important
    }
    .m-xs-12,.ml-xs-12,.mx-xs-12{
        margin-left:12px!important
    }
    .m-xs-16,.ml-xs-16,.mx-xs-16{
        margin-left:16px!important
    }
    .m-xs-20,.ml-xs-20,.mx-xs-20{
        margin-left:20px!important
    }
    .m-xs-24,.ml-xs-24,.mx-xs-24{
        margin-left:24px!important
    }
    .m-xs-24,.ml-xs-28,.mx-xs-28{
        margin-left:28px!important
    }
    .m-xs-32,.ml-xs-32,.mx-xs-32{
        margin-left:32px!important
    }
    .m-xs-36,.ml-xs-36,.mx-xs-36{
        margin-left:36px!important
    }
    .m-xs-40,.ml-xs-40,.mx-xs-40{
        margin-left:40px!important
    }
    .m-xs-44,.ml-xs-44,.mx-xs-44{
        margin-left:44px!important
    }
    .m-xs-48,.ml-xs-48,.mx-xs-48{
        margin-left:48px!important
    }
    .m-xs-60,.ml-xs-60,.mx-xs-60{
        margin-left:60px!important
    }
    .m-xs-80,.ml-xs-80,.mx-xs-80{
        margin-left:80px!important
    }
    .m-xs-120,.ml-xs-120,.mx-xs-120{
        margin-left:120px!important
    }
    .m-xs-0,.mr-xs-0,.mx-xs-0{
        margin-right:0!important
    }
    .m-xs-4,.mr-xs-4,.mx-xs-4{
        margin-right:4px!important
    }
    .m-xs-8,.mr-xs-8,.mx-xs-8{
        margin-right:8px!important
    }
    .m-xs-12,.mr-xs-12,.mx-xs-12{
        margin-right:12px!important
    }
    .m-xs-16,.mr-xs-16,.mx-xs-16{
        margin-right:16px!important
    }
    .m-xs-20,.mr-xs-20,.mx-xs-20{
        margin-right:20px!important
    }
    .m-xs-24,.mr-xs-24,.mx-xs-24{
        margin-right:24px!important
    }
    .m-xs-24,.mr-xs-28,.mx-xs-28{
        margin-right:28px!important
    }
    .m-xs-32,.mr-xs-32,.mx-xs-32{
        margin-right:32px!important
    }
    .m-xs-36,.mr-xs-36,.mx-xs-36{
        margin-right:36px!important
    }
    .m-xs-40,.mr-xs-40,.mx-xs-40{
        margin-right:40px!important
    }
    .m-xs-44,.mr-xs-44,.mx-xs-44{
        margin-right:44px!important
    }
    .m-xs-48,.mr-xs-48,.mx-xs-48{
        margin-right:48px!important
    }
    .m-xs-60,.mr-xs-60,.mx-xs-60{
        margin-right:60px!important
    }
    .m-xs-80,.mr-xs-80,.mx-xs-80{
        margin-right:80px!important
    }
    .m-xs-120,.mr-xs-120,.mx-xs-120{
        margin-right:120px!important
    }
}
@media (min-width:768px){
    .m-sm-0,.mt-sm-0,.my-sm-0{
        margin-top:0!important
    }
    .m-sm-4,.mt-sm-4,.my-sm-4{
        margin-top:4px!important
    }
    .m-sm-8,.mt-sm-8,.my-sm-8{
        margin-top:8px!important
    }
    .m-sm-12,.mt-sm-12,.my-sm-12{
        margin-top:12px!important
    }
    .m-sm-16,.mt-sm-16,.my-sm-16{
        margin-top:16px!important
    }
    .m-sm-20,.mt-sm-20,.my-sm-20{
        margin-top:20px!important
    }
    .m-sm-24,.mt-sm-24,.my-sm-24{
        margin-top:24px!important
    }
    .m-sm-24,.mt-sm-28,.my-sm-28{
        margin-top:28px!important
    }
    .m-sm-32,.mt-sm-32,.my-sm-32{
        margin-top:32px!important
    }
    .m-sm-36,.mt-sm-36,.my-sm-36{
        margin-top:36px!important
    }
    .m-sm-40,.mt-sm-40,.my-sm-40{
        margin-top:40px!important
    }
    .m-sm-44,.mt-sm-44,.my-sm-44{
        margin-top:44px!important
    }
    .m-sm-48,.mt-sm-48,.my-sm-48{
        margin-top:48px!important
    }
    .m-sm-60,.mt-sm-60,.my-sm-60{
        margin-top:60px!important
    }
    .m-sm-80,.mt-sm-80,.my-sm-80{
        margin-top:80px!important
    }
    .m-sm-120,.mt-sm-120,.my-sm-120{
        margin-top:120px!important
    }
    .m-sm-0,.mb-sm-0,.my-sm-0{
        margin-bottom:0!important
    }
    .m-sm-4,.mb-sm-4,.my-sm-4{
        margin-bottom:4px!important
    }
    .m-sm-8,.mb-sm-8,.my-sm-8{
        margin-bottom:8px!important
    }
    .m-sm-12,.mb-sm-12,.my-sm-12{
        margin-bottom:12px!important
    }
    .m-sm-16,.mb-sm-16,.my-sm-16{
        margin-bottom:16px!important
    }
    .m-sm-20,.mb-sm-20,.my-sm-20{
        margin-bottom:20px!important
    }
    .m-sm-24,.mb-sm-24,.my-sm-24{
        margin-bottom:24px!important
    }
    .m-sm-24,.mb-sm-28,.my-sm-28{
        margin-bottom:28px!important
    }
    .m-sm-32,.mb-sm-32,.my-sm-32{
        margin-bottom:32px!important
    }
    .m-sm-36,.mb-sm-36,.my-sm-36{
        margin-bottom:36px!important
    }
    .m-sm-40,.mb-sm-40,.my-sm-40{
        margin-bottom:40px!important
    }
    .m-sm-44,.mb-sm-44,.my-sm-44{
        margin-bottom:44px!important
    }
    .m-sm-48,.mb-sm-48,.my-sm-48{
        margin-bottom:48px!important
    }
    .m-sm-60,.mb-sm-60,.my-sm-60{
        margin-bottom:60px!important
    }
    .m-sm-80,.mb-sm-80,.my-sm-80{
        margin-bottom:80px!important
    }
    .m-sm-120,.mb-sm-120,.my-sm-120{
        margin-bottom:120px!important
    }
    .m-sm-0,.ml-sm-0,.mx-sm-0{
        margin-left:0!important
    }
    .m-sm-4,.ml-sm-4,.mx-sm-4{
        margin-left:4px!important
    }
    .m-sm-8,.ml-sm-8,.mx-sm-8{
        margin-left:8px!important
    }
    .m-sm-12,.ml-sm-12,.mx-sm-12{
        margin-left:12px!important
    }
    .m-sm-16,.ml-sm-16,.mx-sm-16{
        margin-left:16px!important
    }
    .m-sm-20,.ml-sm-20,.mx-sm-20{
        margin-left:20px!important
    }
    .m-sm-24,.ml-sm-24,.mx-sm-24{
        margin-left:24px!important
    }
    .m-sm-24,.ml-sm-28,.mx-sm-28{
        margin-left:28px!important
    }
    .m-sm-32,.ml-sm-32,.mx-sm-32{
        margin-left:32px!important
    }
    .m-sm-36,.ml-sm-36,.mx-sm-36{
        margin-left:36px!important
    }
    .m-sm-40,.ml-sm-40,.mx-sm-40{
        margin-left:40px!important
    }
    .m-sm-44,.ml-sm-44,.mx-sm-44{
        margin-left:44px!important
    }
    .m-sm-48,.ml-sm-48,.mx-sm-48{
        margin-left:48px!important
    }
    .m-sm-60,.ml-sm-60,.mx-sm-60{
        margin-left:60px!important
    }
    .m-sm-80,.ml-sm-80,.mx-sm-80{
        margin-left:80px!important
    }
    .m-sm-120,.ml-sm-120,.mx-sm-120{
        margin-left:120px!important
    }
    .m-sm-0,.mr-sm-0,.mx-sm-0{
        margin-right:0!important
    }
    .m-sm-4,.mr-sm-4,.mx-sm-4{
        margin-right:4px!important
    }
    .m-sm-8,.mr-sm-8,.mx-sm-8{
        margin-right:8px!important
    }
    .m-sm-12,.mr-sm-12,.mx-sm-12{
        margin-right:12px!important
    }
    .m-sm-16,.mr-sm-16,.mx-sm-16{
        margin-right:16px!important
    }
    .m-sm-20,.mr-sm-20,.mx-sm-20{
        margin-right:20px!important
    }
    .m-sm-24,.mr-sm-24,.mx-sm-24{
        margin-right:24px!important
    }
    .m-sm-24,.mr-sm-28,.mx-sm-28{
        margin-right:28px!important
    }
    .m-sm-32,.mr-sm-32,.mx-sm-32{
        margin-right:32px!important
    }
    .m-sm-36,.mr-sm-36,.mx-sm-36{
        margin-right:36px!important
    }
    .m-sm-40,.mr-sm-40,.mx-sm-40{
        margin-right:40px!important
    }
    .m-sm-44,.mr-sm-44,.mx-sm-44{
        margin-right:44px!important
    }
    .m-sm-48,.mr-sm-48,.mx-sm-48{
        margin-right:48px!important
    }
    .m-sm-60,.mr-sm-60,.mx-sm-60{
        margin-right:60px!important
    }
    .m-sm-80,.mr-sm-80,.mx-sm-80{
        margin-right:80px!important
    }
    .m-sm-120,.mr-sm-120,.mx-sm-120{
        margin-right:120px!important
    }
}
@media (min-width:1024px){
    .m-md-0,.mt-md-0,.my-md-0{
        margin-top:0!important
    }
    .m-md-4,.mt-md-4,.my-md-4{
        margin-top:4px!important
    }
    .m-md-8,.mt-md-8,.my-md-8{
        margin-top:8px!important
    }
    .m-md-12,.mt-md-12,.my-md-12{
        margin-top:12px!important
    }
    .m-md-16,.mt-md-16,.my-md-16{
        margin-top:16px!important
    }
    .m-md-20,.mt-md-20,.my-md-20{
        margin-top:20px!important
    }
    .m-md-24,.mt-md-24,.my-md-24{
        margin-top:24px!important
    }
    .m-md-24,.mt-md-28,.my-md-28{
        margin-top:28px!important
    }
    .m-md-32,.mt-md-32,.my-md-32{
        margin-top:32px!important
    }
    .m-md-36,.mt-md-36,.my-md-36{
        margin-top:36px!important
    }
    .m-md-40,.mt-md-40,.my-md-40{
        margin-top:40px!important
    }
    .m-md-44,.mt-md-44,.my-md-44{
        margin-top:44px!important
    }
    .m-md-48,.mt-md-48,.my-md-48{
        margin-top:48px!important
    }
    .m-md-60,.mt-md-60,.my-md-60{
        margin-top:60px!important
    }
    .m-md-80,.mt-md-80,.my-md-80{
        margin-top:80px!important
    }
    .m-md-120,.mt-md-120,.my-md-120{
        margin-top:120px!important
    }
    .m-md-0,.mb-md-0,.my-md-0{
        margin-bottom:0!important
    }
    .m-md-4,.mb-md-4,.my-md-4{
        margin-bottom:4px!important
    }
    .m-md-8,.mb-md-8,.my-md-8{
        margin-bottom:8px!important
    }
    .m-md-12,.mb-md-12,.my-md-12{
        margin-bottom:12px!important
    }
    .m-md-16,.mb-md-16,.my-md-16{
        margin-bottom:16px!important
    }
    .m-md-20,.mb-md-20,.my-md-20{
        margin-bottom:20px!important
    }
    .m-md-24,.mb-md-24,.my-md-24{
        margin-bottom:24px!important
    }
    .m-md-24,.mb-md-28,.my-md-28{
        margin-bottom:28px!important
    }
    .m-md-32,.mb-md-32,.my-md-32{
        margin-bottom:32px!important
    }
    .m-md-36,.mb-md-36,.my-md-36{
        margin-bottom:36px!important
    }
    .m-md-40,.mb-md-40,.my-md-40{
        margin-bottom:40px!important
    }
    .m-md-44,.mb-md-44,.my-md-44{
        margin-bottom:44px!important
    }
    .m-md-48,.mb-md-48,.my-md-48{
        margin-bottom:48px!important
    }
    .m-md-60,.mb-md-60,.my-md-60{
        margin-bottom:60px!important
    }
    .m-md-80,.mb-md-80,.my-md-80{
        margin-bottom:80px!important
    }
    .m-md-120,.mb-md-120,.my-md-120{
        margin-bottom:120px!important
    }
    .m-md-0,.ml-md-0,.mx-md-0{
        margin-left:0!important
    }
    .m-md-4,.ml-md-4,.mx-md-4{
        margin-left:4px!important
    }
    .m-md-8,.ml-md-8,.mx-md-8{
        margin-left:8px!important
    }
    .m-md-12,.ml-md-12,.mx-md-12{
        margin-left:12px!important
    }
    .m-md-16,.ml-md-16,.mx-md-16{
        margin-left:16px!important
    }
    .m-md-20,.ml-md-20,.mx-md-20{
        margin-left:20px!important
    }
    .m-md-24,.ml-md-24,.mx-md-24{
        margin-left:24px!important
    }
    .m-md-24,.ml-md-28,.mx-md-28{
        margin-left:28px!important
    }
    .m-md-32,.ml-md-32,.mx-md-32{
        margin-left:32px!important
    }
    .m-md-36,.ml-md-36,.mx-md-36{
        margin-left:36px!important
    }
    .m-md-40,.ml-md-40,.mx-md-40{
        margin-left:40px!important
    }
    .m-md-44,.ml-md-44,.mx-md-44{
        margin-left:44px!important
    }
    .m-md-48,.ml-md-48,.mx-md-48{
        margin-left:48px!important
    }
    .m-md-60,.ml-md-60,.mx-md-60{
        margin-left:60px!important
    }
    .m-md-80,.ml-md-80,.mx-md-80{
        margin-left:80px!important
    }
    .m-md-120,.ml-md-120,.mx-md-120{
        margin-left:120px!important
    }
    .m-md-0,.mr-md-0,.mx-md-0{
        margin-right:0!important
    }
    .m-md-4,.mr-md-4,.mx-md-4{
        margin-right:4px!important
    }
    .m-md-8,.mr-md-8,.mx-md-8{
        margin-right:8px!important
    }
    .m-md-12,.mr-md-12,.mx-md-12{
        margin-right:12px!important
    }
    .m-md-16,.mr-md-16,.mx-md-16{
        margin-right:16px!important
    }
    .m-md-20,.mr-md-20,.mx-md-20{
        margin-right:20px!important
    }
    .m-md-24,.mr-md-24,.mx-md-24{
        margin-right:24px!important
    }
    .m-md-24,.mr-md-28,.mx-md-28{
        margin-right:28px!important
    }
    .m-md-32,.mr-md-32,.mx-md-32{
        margin-right:32px!important
    }
    .m-md-36,.mr-md-36,.mx-md-36{
        margin-right:36px!important
    }
    .m-md-40,.mr-md-40,.mx-md-40{
        margin-right:40px!important
    }
    .m-md-44,.mr-md-44,.mx-md-44{
        margin-right:44px!important
    }
    .m-md-48,.mr-md-48,.mx-md-48{
        margin-right:48px!important
    }
    .m-md-60,.mr-md-60,.mx-md-60{
        margin-right:60px!important
    }
    .m-md-80,.mr-md-80,.mx-md-80{
        margin-right:80px!important
    }
    .m-md-120,.mr-md-120,.mx-md-120{
        margin-right:120px!important
    }
}
@media (min-width:1280px){
    .m-lg-0,.mt-lg-0,.my-lg-0{
        margin-top:0!important
    }
    .m-lg-4,.mt-lg-4,.my-lg-4{
        margin-top:4px!important
    }
    .m-lg-8,.mt-lg-8,.my-lg-8{
        margin-top:8px!important
    }
    .m-lg-12,.mt-lg-12,.my-lg-12{
        margin-top:12px!important
    }
    .m-lg-16,.mt-lg-16,.my-lg-16{
        margin-top:16px!important
    }
    .m-lg-20,.mt-lg-20,.my-lg-20{
        margin-top:20px!important
    }
    .m-lg-24,.mt-lg-24,.my-lg-24{
        margin-top:24px!important
    }
    .m-lg-24,.mt-lg-28,.my-lg-28{
        margin-top:28px!important
    }
    .m-lg-32,.mt-lg-32,.my-lg-32{
        margin-top:32px!important
    }
    .m-lg-36,.mt-lg-36,.my-lg-36{
        margin-top:36px!important
    }
    .m-lg-40,.mt-lg-40,.my-lg-40{
        margin-top:40px!important
    }
    .m-lg-44,.mt-lg-44,.my-lg-44{
        margin-top:44px!important
    }
    .m-lg-48,.mt-lg-48,.my-lg-48{
        margin-top:48px!important
    }
    .m-lg-60,.mt-lg-60,.my-lg-60{
        margin-top:60px!important
    }
    .m-lg-80,.mt-lg-80,.my-lg-80{
        margin-top:80px!important
    }
    .m-lg-120,.mt-lg-120,.my-lg-120{
        margin-top:120px!important
    }
    .m-lg-0,.mb-lg-0,.my-lg-0{
        margin-bottom:0!important
    }
    .m-lg-4,.mb-lg-4,.my-lg-4{
        margin-bottom:4px!important
    }
    .m-lg-8,.mb-lg-8,.my-lg-8{
        margin-bottom:8px!important
    }
    .m-lg-12,.mb-lg-12,.my-lg-12{
        margin-bottom:12px!important
    }
    .m-lg-16,.mb-lg-16,.my-lg-16{
        margin-bottom:16px!important
    }
    .m-lg-20,.mb-lg-20,.my-lg-20{
        margin-bottom:20px!important
    }
    .m-lg-24,.mb-lg-24,.my-lg-24{
        margin-bottom:24px!important
    }
    .m-lg-24,.mb-lg-28,.my-lg-28{
        margin-bottom:28px!important
    }
    .m-lg-32,.mb-lg-32,.my-lg-32{
        margin-bottom:32px!important
    }
    .m-lg-36,.mb-lg-36,.my-lg-36{
        margin-bottom:36px!important
    }
    .m-lg-40,.mb-lg-40,.my-lg-40{
        margin-bottom:40px!important
    }
    .m-lg-44,.mb-lg-44,.my-lg-44{
        margin-bottom:44px!important
    }
    .m-lg-48,.mb-lg-48,.my-lg-48{
        margin-bottom:48px!important
    }
    .m-lg-60,.mb-lg-60,.my-lg-60{
        margin-bottom:60px!important
    }
    .m-lg-80,.mb-lg-80,.my-lg-80{
        margin-bottom:80px!important
    }
    .m-lg-120,.mb-lg-120,.my-lg-120{
        margin-bottom:120px!important
    }
    .m-lg-0,.ml-lg-0,.mx-lg-0{
        margin-left:0!important
    }
    .m-lg-4,.ml-lg-4,.mx-lg-4{
        margin-left:4px!important
    }
    .m-lg-8,.ml-lg-8,.mx-lg-8{
        margin-left:8px!important
    }
    .m-lg-12,.ml-lg-12,.mx-lg-12{
        margin-left:12px!important
    }
    .m-lg-16,.ml-lg-16,.mx-lg-16{
        margin-left:16px!important
    }
    .m-lg-20,.ml-lg-20,.mx-lg-20{
        margin-left:20px!important
    }
    .m-lg-24,.ml-lg-24,.mx-lg-24{
        margin-left:24px!important
    }
    .m-lg-24,.ml-lg-28,.mx-lg-28{
        margin-left:28px!important
    }
    .m-lg-32,.ml-lg-32,.mx-lg-32{
        margin-left:32px!important
    }
    .m-lg-36,.ml-lg-36,.mx-lg-36{
        margin-left:36px!important
    }
    .m-lg-40,.ml-lg-40,.mx-lg-40{
        margin-left:40px!important
    }
    .m-lg-44,.ml-lg-44,.mx-lg-44{
        margin-left:44px!important
    }
    .m-lg-48,.ml-lg-48,.mx-lg-48{
        margin-left:48px!important
    }
    .m-lg-60,.ml-lg-60,.mx-lg-60{
        margin-left:60px!important
    }
    .m-lg-80,.ml-lg-80,.mx-lg-80{
        margin-left:80px!important
    }
    .m-lg-120,.ml-lg-120,.mx-lg-120{
        margin-left:120px!important
    }
    .m-lg-0,.mr-lg-0,.mx-lg-0{
        margin-right:0!important
    }
    .m-lg-4,.mr-lg-4,.mx-lg-4{
        margin-right:4px!important
    }
    .m-lg-8,.mr-lg-8,.mx-lg-8{
        margin-right:8px!important
    }
    .m-lg-12,.mr-lg-12,.mx-lg-12{
        margin-right:12px!important
    }
    .m-lg-16,.mr-lg-16,.mx-lg-16{
        margin-right:16px!important
    }
    .m-lg-20,.mr-lg-20,.mx-lg-20{
        margin-right:20px!important
    }
    .m-lg-24,.mr-lg-24,.mx-lg-24{
        margin-right:24px!important
    }
    .m-lg-24,.mr-lg-28,.mx-lg-28{
        margin-right:28px!important
    }
    .m-lg-32,.mr-lg-32,.mx-lg-32{
        margin-right:32px!important
    }
    .m-lg-36,.mr-lg-36,.mx-lg-36{
        margin-right:36px!important
    }
    .m-lg-40,.mr-lg-40,.mx-lg-40{
        margin-right:40px!important
    }
    .m-lg-44,.mr-lg-44,.mx-lg-44{
        margin-right:44px!important
    }
    .m-lg-48,.mr-lg-48,.mx-lg-48{
        margin-right:48px!important
    }
    .m-lg-60,.mr-lg-60,.mx-lg-60{
        margin-right:60px!important
    }
    .m-lg-80,.mr-lg-80,.mx-lg-80{
        margin-right:80px!important
    }
    .m-lg-120,.mr-lg-120,.mx-lg-120{
        margin-right:120px!important
    }
}
@media (min-width:1360px){
    .m-xl-0,.mt-xl-0,.my-xl-0{
        margin-top:0!important
    }
    .m-xl-4,.mt-xl-4,.my-xl-4{
        margin-top:4px!important
    }
    .m-xl-8,.mt-xl-8,.my-xl-8{
        margin-top:8px!important
    }
    .m-xl-12,.mt-xl-12,.my-xl-12{
        margin-top:12px!important
    }
    .m-xl-16,.mt-xl-16,.my-xl-16{
        margin-top:16px!important
    }
    .m-xl-20,.mt-xl-20,.my-xl-20{
        margin-top:20px!important
    }
    .m-xl-24,.mt-xl-24,.my-xl-24{
        margin-top:24px!important
    }
    .m-xl-24,.mt-xl-28,.my-xl-28{
        margin-top:28px!important
    }
    .m-xl-32,.mt-xl-32,.my-xl-32{
        margin-top:32px!important
    }
    .m-xl-36,.mt-xl-36,.my-xl-36{
        margin-top:36px!important
    }
    .m-xl-40,.mt-xl-40,.my-xl-40{
        margin-top:40px!important
    }
    .m-xl-44,.mt-xl-44,.my-xl-44{
        margin-top:44px!important
    }
    .m-xl-48,.mt-xl-48,.my-xl-48{
        margin-top:48px!important
    }
    .m-xl-60,.mt-xl-60,.my-xl-60{
        margin-top:60px!important
    }
    .m-xl-80,.mt-xl-80,.my-xl-80{
        margin-top:80px!important
    }
    .m-xl-120,.mt-xl-120,.my-xl-120{
        margin-top:120px!important
    }
    .m-xl-0,.mb-xl-0,.my-xl-0{
        margin-bottom:0!important
    }
    .m-xl-4,.mb-xl-4,.my-xl-4{
        margin-bottom:4px!important
    }
    .m-xl-8,.mb-xl-8,.my-xl-8{
        margin-bottom:8px!important
    }
    .m-xl-12,.mb-xl-12,.my-xl-12{
        margin-bottom:12px!important
    }
    .m-xl-16,.mb-xl-16,.my-xl-16{
        margin-bottom:16px!important
    }
    .m-xl-20,.mb-xl-20,.my-xl-20{
        margin-bottom:20px!important
    }
    .m-xl-24,.mb-xl-24,.my-xl-24{
        margin-bottom:24px!important
    }
    .m-xl-24,.mb-xl-28,.my-xl-28{
        margin-bottom:28px!important
    }
    .m-xl-32,.mb-xl-32,.my-xl-32{
        margin-bottom:32px!important
    }
    .m-xl-36,.mb-xl-36,.my-xl-36{
        margin-bottom:36px!important
    }
    .m-xl-40,.mb-xl-40,.my-xl-40{
        margin-bottom:40px!important
    }
    .m-xl-44,.mb-xl-44,.my-xl-44{
        margin-bottom:44px!important
    }
    .m-xl-48,.mb-xl-48,.my-xl-48{
        margin-bottom:48px!important
    }
    .m-xl-60,.mb-xl-60,.my-xl-60{
        margin-bottom:60px!important
    }
    .m-xl-80,.mb-xl-80,.my-xl-80{
        margin-bottom:80px!important
    }
    .m-xl-120,.mb-xl-120,.my-xl-120{
        margin-bottom:120px!important
    }
    .m-xl-0,.ml-xl-0,.mx-xl-0{
        margin-left:0!important
    }
    .m-xl-4,.ml-xl-4,.mx-xl-4{
        margin-left:4px!important
    }
    .m-xl-8,.ml-xl-8,.mx-xl-8{
        margin-left:8px!important
    }
    .m-xl-12,.ml-xl-12,.mx-xl-12{
        margin-left:12px!important
    }
    .m-xl-16,.ml-xl-16,.mx-xl-16{
        margin-left:16px!important
    }
    .m-xl-20,.ml-xl-20,.mx-xl-20{
        margin-left:20px!important
    }
    .m-xl-24,.ml-xl-24,.mx-xl-24{
        margin-left:24px!important
    }
    .m-xl-24,.ml-xl-28,.mx-xl-28{
        margin-left:28px!important
    }
    .m-xl-32,.ml-xl-32,.mx-xl-32{
        margin-left:32px!important
    }
    .m-xl-36,.ml-xl-36,.mx-xl-36{
        margin-left:36px!important
    }
    .m-xl-40,.ml-xl-40,.mx-xl-40{
        margin-left:40px!important
    }
    .m-xl-44,.ml-xl-44,.mx-xl-44{
        margin-left:44px!important
    }
    .m-xl-48,.ml-xl-48,.mx-xl-48{
        margin-left:48px!important
    }
    .m-xl-60,.ml-xl-60,.mx-xl-60{
        margin-left:60px!important
    }
    .m-xl-80,.ml-xl-80,.mx-xl-80{
        margin-left:80px!important
    }
    .m-xl-120,.ml-xl-120,.mx-xl-120{
        margin-left:120px!important
    }
    .m-xl-0,.mr-xl-0,.mx-xl-0{
        margin-right:0!important
    }
    .m-xl-4,.mr-xl-4,.mx-xl-4{
        margin-right:4px!important
    }
    .m-xl-8,.mr-xl-8,.mx-xl-8{
        margin-right:8px!important
    }
    .m-xl-12,.mr-xl-12,.mx-xl-12{
        margin-right:12px!important
    }
    .m-xl-16,.mr-xl-16,.mx-xl-16{
        margin-right:16px!important
    }
    .m-xl-20,.mr-xl-20,.mx-xl-20{
        margin-right:20px!important
    }
    .m-xl-24,.mr-xl-24,.mx-xl-24{
        margin-right:24px!important
    }
    .m-xl-24,.mr-xl-28,.mx-xl-28{
        margin-right:28px!important
    }
    .m-xl-32,.mr-xl-32,.mx-xl-32{
        margin-right:32px!important
    }
    .m-xl-36,.mr-xl-36,.mx-xl-36{
        margin-right:36px!important
    }
    .m-xl-40,.mr-xl-40,.mx-xl-40{
        margin-right:40px!important
    }
    .m-xl-44,.mr-xl-44,.mx-xl-44{
        margin-right:44px!important
    }
    .m-xl-48,.mr-xl-48,.mx-xl-48{
        margin-right:48px!important
    }
    .m-xl-60,.mr-xl-60,.mx-xl-60{
        margin-right:60px!important
    }
    .m-xl-80,.mr-xl-80,.mx-xl-80{
        margin-right:80px!important
    }
    .m-xl-120,.mr-xl-120,.mx-xl-120{
        margin-right:120px!important
    }
}
@media (min-width:1680px){
    .m-xxl-0,.mt-xxl-0,.my-xxl-0{
        margin-top:0!important
    }
    .m-xxl-4,.mt-xxl-4,.my-xxl-4{
        margin-top:4px!important
    }
    .m-xxl-8,.mt-xxl-8,.my-xxl-8{
        margin-top:8px!important
    }
    .m-xxl-12,.mt-xxl-12,.my-xxl-12{
        margin-top:12px!important
    }
    .m-xxl-16,.mt-xxl-16,.my-xxl-16{
        margin-top:16px!important
    }
    .m-xxl-20,.mt-xxl-20,.my-xxl-20{
        margin-top:20px!important
    }
    .m-xxl-24,.mt-xxl-24,.my-xxl-24{
        margin-top:24px!important
    }
    .m-xxl-24,.mt-xxl-28,.my-xxl-28{
        margin-top:28px!important
    }
    .m-xxl-32,.mt-xxl-32,.my-xxl-32{
        margin-top:32px!important
    }
    .m-xxl-36,.mt-xxl-36,.my-xxl-36{
        margin-top:36px!important
    }
    .m-xxl-40,.mt-xxl-40,.my-xxl-40{
        margin-top:40px!important
    }
    .m-xxl-44,.mt-xxl-44,.my-xxl-44{
        margin-top:44px!important
    }
    .m-xxl-48,.mt-xxl-48,.my-xxl-48{
        margin-top:48px!important
    }
    .m-xxl-60,.mt-xxl-60,.my-xxl-60{
        margin-top:60px!important
    }
    .m-xxl-80,.mt-xxl-80,.my-xxl-80{
        margin-top:80px!important
    }
    .m-xxl-120,.mt-xxl-120,.my-xxl-120{
        margin-top:120px!important
    }
    .m-xxl-0,.mb-xxl-0,.my-xxl-0{
        margin-bottom:0!important
    }
    .m-xxl-4,.mb-xxl-4,.my-xxl-4{
        margin-bottom:4px!important
    }
    .m-xxl-8,.mb-xxl-8,.my-xxl-8{
        margin-bottom:8px!important
    }
    .m-xxl-12,.mb-xxl-12,.my-xxl-12{
        margin-bottom:12px!important
    }
    .m-xxl-16,.mb-xxl-16,.my-xxl-16{
        margin-bottom:16px!important
    }
    .m-xxl-20,.mb-xxl-20,.my-xxl-20{
        margin-bottom:20px!important
    }
    .m-xxl-24,.mb-xxl-24,.my-xxl-24{
        margin-bottom:24px!important
    }
    .m-xxl-24,.mb-xxl-28,.my-xxl-28{
        margin-bottom:28px!important
    }
    .m-xxl-32,.mb-xxl-32,.my-xxl-32{
        margin-bottom:32px!important
    }
    .m-xxl-36,.mb-xxl-36,.my-xxl-36{
        margin-bottom:36px!important
    }
    .m-xxl-40,.mb-xxl-40,.my-xxl-40{
        margin-bottom:40px!important
    }
    .m-xxl-44,.mb-xxl-44,.my-xxl-44{
        margin-bottom:44px!important
    }
    .m-xxl-48,.mb-xxl-48,.my-xxl-48{
        margin-bottom:48px!important
    }
    .m-xxl-60,.mb-xxl-60,.my-xxl-60{
        margin-bottom:60px!important
    }
    .m-xxl-80,.mb-xxl-80,.my-xxl-80{
        margin-bottom:80px!important
    }
    .m-xxl-120,.mb-xxl-120,.my-xxl-120{
        margin-bottom:120px!important
    }
    .m-xxl-0,.ml-xxl-0,.mx-xxl-0{
        margin-left:0!important
    }
    .m-xxl-4,.ml-xxl-4,.mx-xxl-4{
        margin-left:4px!important
    }
    .m-xxl-8,.ml-xxl-8,.mx-xxl-8{
        margin-left:8px!important
    }
    .m-xxl-12,.ml-xxl-12,.mx-xxl-12{
        margin-left:12px!important
    }
    .m-xxl-16,.ml-xxl-16,.mx-xxl-16{
        margin-left:16px!important
    }
    .m-xxl-20,.ml-xxl-20,.mx-xxl-20{
        margin-left:20px!important
    }
    .m-xxl-24,.ml-xxl-24,.mx-xxl-24{
        margin-left:24px!important
    }
    .m-xxl-24,.ml-xxl-28,.mx-xxl-28{
        margin-left:28px!important
    }
    .m-xxl-32,.ml-xxl-32,.mx-xxl-32{
        margin-left:32px!important
    }
    .m-xxl-36,.ml-xxl-36,.mx-xxl-36{
        margin-left:36px!important
    }
    .m-xxl-40,.ml-xxl-40,.mx-xxl-40{
        margin-left:40px!important
    }
    .m-xxl-44,.ml-xxl-44,.mx-xxl-44{
        margin-left:44px!important
    }
    .m-xxl-48,.ml-xxl-48,.mx-xxl-48{
        margin-left:48px!important
    }
    .m-xxl-60,.ml-xxl-60,.mx-xxl-60{
        margin-left:60px!important
    }
    .m-xxl-80,.ml-xxl-80,.mx-xxl-80{
        margin-left:80px!important
    }
    .m-xxl-120,.ml-xxl-120,.mx-xxl-120{
        margin-left:120px!important
    }
    .m-xxl-0,.mr-xxl-0,.mx-xxl-0{
        margin-right:0!important
    }
    .m-xxl-4,.mr-xxl-4,.mx-xxl-4{
        margin-right:4px!important
    }
    .m-xxl-8,.mr-xxl-8,.mx-xxl-8{
        margin-right:8px!important
    }
    .m-xxl-12,.mr-xxl-12,.mx-xxl-12{
        margin-right:12px!important
    }
    .m-xxl-16,.mr-xxl-16,.mx-xxl-16{
        margin-right:16px!important
    }
    .m-xxl-20,.mr-xxl-20,.mx-xxl-20{
        margin-right:20px!important
    }
    .m-xxl-24,.mr-xxl-24,.mx-xxl-24{
        margin-right:24px!important
    }
    .m-xxl-24,.mr-xxl-28,.mx-xxl-28{
        margin-right:28px!important
    }
    .m-xxl-32,.mr-xxl-32,.mx-xxl-32{
        margin-right:32px!important
    }
    .m-xxl-36,.mr-xxl-36,.mx-xxl-36{
        margin-right:36px!important
    }
    .m-xxl-40,.mr-xxl-40,.mx-xxl-40{
        margin-right:40px!important
    }
    .m-xxl-44,.mr-xxl-44,.mx-xxl-44{
        margin-right:44px!important
    }
    .m-xxl-48,.mr-xxl-48,.mx-xxl-48{
        margin-right:48px!important
    }
    .m-xxl-60,.mr-xxl-60,.mx-xxl-60{
        margin-right:60px!important
    }
    .m-xxl-80,.mr-xxl-80,.mx-xxl-80{
        margin-right:80px!important
    }
    .m-xxl-120,.mr-xxl-120,.mx-xxl-120{
        margin-right:120px!important
    }
}
.p-0,.pt-0,.py-0{
    padding-top:0!important
}
.p-4,.pt-4,.py-4{
    padding-top:4px!important
}
.p-8,.pt-8,.py-8{
    padding-top:8px!important
}
.p-12,.pt-12,.py-12{
    padding-top:12px!important
}
.p-16,.pt-16,.py-16{
    padding-top:16px!important
}
.p-20,.pt-20,.py-20{
    padding-top:20px!important
}
.p-24,.pt-24,.py-24{
    padding-top:24px!important
}
.p-28,.pt-28,.py-28{
    padding-top:28px!important
}
.p-32,.pt-32,.py-32{
    padding-top:32px!important
}
.p-36,.pt-36,.py-36{
    padding-top:36px!important
}
.p-40,.pt-40,.py-40{
    padding-top:40px!important
}
.p-44,.pt-44,.py-44{
    padding-top:44px!important
}
.p-48,.pt-48,.py-48{
    padding-top:48px!important
}
.p-60,.pt-60,.py-60{
    padding-top:60px!important
}
.p-80,.pt-80,.py-80{
    padding-top:80px!important
}
.p-120,.pt-120,.py-120{
    padding-top:120px!important
}
.p-0,.pb-0,.py-0{
    padding-bottom:0!important
}
.p-4,.pb-4,.py-4{
    padding-bottom:4px!important
}
.p-8,.pb-8,.py-8{
    padding-bottom:8px!important
}
.p-12,.pb-12,.py-12{
    padding-bottom:12px!important
}
.p-16,.pb-16,.py-16{
    padding-bottom:16px!important
}
.p-20,.pb-20,.py-20{
    padding-bottom:20px!important
}
.p-24,.pb-24,.py-24{
    padding-bottom:24px!important
}
.p-28,.pb-28,.py-28{
    padding-bottom:28px!important
}
.p-32,.pb-32,.py-32{
    padding-bottom:32px!important
}
.p-36,.pb-36,.py-36{
    padding-bottom:36px!important
}
.p-40,.pb-40,.py-40{
    padding-bottom:40px!important
}
.p-44,.pb-44,.py-44{
    padding-bottom:44px!important
}
.p-48,.pb-48,.py-48{
    padding-bottom:48px!important
}
.p-60,.pb-60,.py-60{
    padding-bottom:60px!important
}
.p-80,.pb-80,.py-80{
    padding-bottom:80px!important
}
.p-120,.pb-120,.py-120{
    padding-bottom:120px!important
}
.p-0,.pl-0,.px-0{
    padding-left:0!important
}
.p-4,.pl-4,.px-4{
    padding-left:4px!important
}
.p-8,.pl-8,.px-8{
    padding-left:8px!important
}
.p-12,.pl-12,.px-12{
    padding-left:12px!important
}
.p-16,.pl-16,.px-16{
    padding-left:16px!important
}
.p-20,.pl-20,.px-20{
    padding-left:20px!important
}
.p-24,.pl-24,.px-24{
    padding-left:24px!important
}
.p-28,.pl-28,.px-28{
    padding-left:28px!important
}
.p-32,.pl-32,.px-32{
    padding-left:32px!important
}
.p-36,.pl-36,.px-36{
    padding-left:36px!important
}
.p-40,.pl-40,.px-40{
    padding-left:40px!important
}
.p-44,.pl-44,.px-44{
    padding-left:44px!important
}
.p-48,.pl-48,.px-48{
    padding-left:48px!important
}
.p-60,.pl-60,.px-60{
    padding-left:60px!important
}
.p-80,.pl-80,.px-80{
    padding-left:80px!important
}
.p-120,.pl-120,.px-120{
    padding-left:120px!important
}
.p-0,.pr-0,.px-0{
    padding-right:0!important
}
.p-4,.pr-4,.px-4{
    padding-right:4px!important
}
.p-8,.pr-8,.px-8{
    padding-right:8px!important
}
.p-12,.pr-12,.px-12{
    padding-right:12px!important
}
.p-16,.pr-16,.px-16{
    padding-right:16px!important
}
.p-20,.pr-20,.px-20{
    padding-right:20px!important
}
.p-24,.pr-24,.px-24{
    padding-right:24px!important
}
.p-28,.pr-28,.px-28{
    padding-right:28px!important
}
.p-32,.pr-32,.px-32{
    padding-right:32px!important
}
.p-36,.pr-36,.px-36{
    padding-right:36px!important
}
.p-40,.pr-40,.px-40{
    padding-right:40px!important
}
.p-44,.pr-44,.px-44{
    padding-right:44px!important
}
.p-48,.pr-48,.px-48{
    padding-right:48px!important
}
.p-60,.pr-60,.px-60{
    padding-right:60px!important
}
.p-80,.pr-80,.px-80{
    padding-right:80px!important
}
.p-120,.pr-120,.px-120{
    padding-right:120px!important
}
@media (min-width:370px){
    .p-3xs-0,.pt-3xs-0,.py-3xs-0{
        padding-top:0!important
    }
    .p-3xs-4,.pt-3xs-4,.py-3xs-4{
        padding-top:4px!important
    }
    .p-3xs-8,.pt-3xs-8,.py-3xs-8{
        padding-top:8px!important
    }
    .p-3xs-12,.pt-3xs-12,.py-3xs-12{
        padding-top:12px!important
    }
    .p-3xs-16,.pt-3xs-16,.py-3xs-16{
        padding-top:16px!important
    }
    .p-3xs-20,.pt-3xs-20,.py-3xs-20{
        padding-top:20px!important
    }
    .p-3xs-24,.pt-3xs-24,.py-3xs-24{
        padding-top:24px!important
    }
    .p-3xs-28,.pt-3xs-28,.py-3xs-28{
        padding-top:28px!important
    }
    .p-3xs-32,.pt-3xs-32,.py-3xs-32{
        padding-top:32px!important
    }
    .p-3xs-36,.pt-3xs-36,.py-3xs-36{
        padding-top:36px!important
    }
    .p-3xs-40,.pt-3xs-40,.py-3xs-40{
        padding-top:40px!important
    }
    .p-3xs-44,.pt-3xs-44,.py-3xs-44{
        padding-top:44px!important
    }
    .p-3xs-48,.pt-3xs-48,.py-3xs-48{
        padding-top:48px!important
    }
    .p-3xs-60,.pt-3xs-60,.py-3xs-60{
        padding-top:60px!important
    }
    .p-3xs-80,.pt-3xs-80,.py-3xs-80{
        padding-top:80px!important
    }
    .p-3xs-120,.pt-3xs-120,.py-3xs-120{
        padding-top:120px!important
    }
    .p-3xs-0,.pb-3xs-0,.py-3xs-0{
        padding-bottom:0!important
    }
    .p-3xs-4,.pb-3xs-4,.py-3xs-4{
        padding-bottom:4px!important
    }
    .p-3xs-8,.pb-3xs-8,.py-3xs-8{
        padding-bottom:8px!important
    }
    .p-3xs-12,.pb-3xs-12,.py-3xs-12{
        padding-bottom:12px!important
    }
    .p-3xs-16,.pb-3xs-16,.py-3xs-16{
        padding-bottom:16px!important
    }
    .p-3xs-20,.pb-3xs-20,.py-3xs-20{
        padding-bottom:20px!important
    }
    .p-3xs-24,.pb-3xs-24,.py-3xs-24{
        padding-bottom:24px!important
    }
    .p-3xs-28,.pb-3xs-28,.py-3xs-28{
        padding-bottom:28px!important
    }
    .p-3xs-32,.pb-3xs-32,.py-3xs-32{
        padding-bottom:32px!important
    }
    .p-3xs-36,.pb-3xs-36,.py-3xs-36{
        padding-bottom:36px!important
    }
    .p-3xs-40,.pb-3xs-40,.py-3xs-40{
        padding-bottom:40px!important
    }
    .p-3xs-44,.pb-3xs-44,.py-3xs-44{
        padding-bottom:44px!important
    }
    .p-3xs-48,.pb-3xs-48,.py-3xs-48{
        padding-bottom:48px!important
    }
    .p-3xs-60,.pb-3xs-60,.py-3xs-60{
        padding-bottom:60px!important
    }
    .p-3xs-80,.pb-3xs-80,.py-3xs-80{
        padding-bottom:80px!important
    }
    .p-3xs-120,.pb-3xs-120,.py-3xs-120{
        padding-bottom:120px!important
    }
    .p-3xs-0,.pl-3xs-0,.px-3xs-0{
        padding-left:0!important
    }
    .p-3xs-4,.pl-3xs-4,.px-3xs-4{
        padding-left:4px!important
    }
    .p-3xs-8,.pl-3xs-8,.px-3xs-8{
        padding-left:8px!important
    }
    .p-3xs-12,.pl-3xs-12,.px-3xs-12{
        padding-left:12px!important
    }
    .p-3xs-16,.pl-3xs-16,.px-3xs-16{
        padding-left:16px!important
    }
    .p-3xs-20,.pl-3xs-20,.px-3xs-20{
        padding-left:20px!important
    }
    .p-3xs-24,.pl-3xs-24,.px-3xs-24{
        padding-left:24px!important
    }
    .p-3xs-28,.pl-3xs-28,.px-3xs-28{
        padding-left:28px!important
    }
    .p-3xs-32,.pl-3xs-32,.px-3xs-32{
        padding-left:32px!important
    }
    .p-3xs-36,.pl-3xs-36,.px-3xs-36{
        padding-left:36px!important
    }
    .p-3xs-40,.pl-3xs-40,.px-3xs-40{
        padding-left:40px!important
    }
    .p-3xs-44,.pl-3xs-44,.px-3xs-44{
        padding-left:44px!important
    }
    .p-3xs-48,.pl-3xs-48,.px-3xs-48{
        padding-left:48px!important
    }
    .p-3xs-60,.pl-3xs-60,.px-3xs-60{
        padding-left:60px!important
    }
    .p-3xs-80,.pl-3xs-80,.px-3xs-80{
        padding-left:80px!important
    }
    .p-3xs-120,.pl-3xs-120,.px-3xs-120{
        padding-left:120px!important
    }
    .p-3xs-0,.pr-3xs-0,.px-3xs-0{
        padding-right:0!important
    }
    .p-3xs-4,.pr-3xs-4,.px-3xs-4{
        padding-right:4px!important
    }
    .p-3xs-8,.pr-3xs-8,.px-3xs-8{
        padding-right:8px!important
    }
    .p-3xs-12,.pr-3xs-12,.px-3xs-12{
        padding-right:12px!important
    }
    .p-3xs-16,.pr-3xs-16,.px-3xs-16{
        padding-right:16px!important
    }
    .p-3xs-20,.pr-3xs-20,.px-3xs-20{
        padding-right:20px!important
    }
    .p-3xs-24,.pr-3xs-24,.px-3xs-24{
        padding-right:24px!important
    }
    .p-3xs-28,.pr-3xs-28,.px-3xs-28{
        padding-right:28px!important
    }
    .p-3xs-32,.pr-3xs-32,.px-3xs-32{
        padding-right:32px!important
    }
    .p-3xs-36,.pr-3xs-36,.px-3xs-36{
        padding-right:36px!important
    }
    .p-3xs-40,.pr-3xs-40,.px-3xs-40{
        padding-right:40px!important
    }
    .p-3xs-44,.pr-3xs-44,.px-3xs-44{
        padding-right:44px!important
    }
    .p-3xs-48,.pr-3xs-48,.px-3xs-48{
        padding-right:48px!important
    }
    .p-3xs-60,.pr-3xs-60,.px-3xs-60{
        padding-right:60px!important
    }
    .p-3xs-80,.pr-3xs-80,.px-3xs-80{
        padding-right:80px!important
    }
    .p-3xs-120,.pr-3xs-120,.px-3xs-120{
        padding-right:120px!important
    }
}
@media (min-width:480px){
    .p-2xs-0,.pt-2xs-0,.py-2xs-0{
        padding-top:0!important
    }
    .p-2xs-4,.pt-2xs-4,.py-2xs-4{
        padding-top:4px!important
    }
    .p-2xs-8,.pt-2xs-8,.py-2xs-8{
        padding-top:8px!important
    }
    .p-2xs-12,.pt-2xs-12,.py-2xs-12{
        padding-top:12px!important
    }
    .p-2xs-16,.pt-2xs-16,.py-2xs-16{
        padding-top:16px!important
    }
    .p-2xs-20,.pt-2xs-20,.py-2xs-20{
        padding-top:20px!important
    }
    .p-2xs-24,.pt-2xs-24,.py-2xs-24{
        padding-top:24px!important
    }
    .p-2xs-28,.pt-2xs-28,.py-2xs-28{
        padding-top:28px!important
    }
    .p-2xs-32,.pt-2xs-32,.py-2xs-32{
        padding-top:32px!important
    }
    .p-2xs-36,.pt-2xs-36,.py-2xs-36{
        padding-top:36px!important
    }
    .p-2xs-40,.pt-2xs-40,.py-2xs-40{
        padding-top:40px!important
    }
    .p-2xs-44,.pt-2xs-44,.py-2xs-44{
        padding-top:44px!important
    }
    .p-2xs-48,.pt-2xs-48,.py-2xs-48{
        padding-top:48px!important
    }
    .p-2xs-60,.pt-2xs-60,.py-2xs-60{
        padding-top:60px!important
    }
    .p-2xs-80,.pt-2xs-80,.py-2xs-80{
        padding-top:80px!important
    }
    .p-2xs-120,.pt-2xs-120,.py-2xs-120{
        padding-top:120px!important
    }
    .p-2xs-0,.pb-2xs-0,.py-2xs-0{
        padding-bottom:0!important
    }
    .p-2xs-4,.pb-2xs-4,.py-2xs-4{
        padding-bottom:4px!important
    }
    .p-2xs-8,.pb-2xs-8,.py-2xs-8{
        padding-bottom:8px!important
    }
    .p-2xs-12,.pb-2xs-12,.py-2xs-12{
        padding-bottom:12px!important
    }
    .p-2xs-16,.pb-2xs-16,.py-2xs-16{
        padding-bottom:16px!important
    }
    .p-2xs-20,.pb-2xs-20,.py-2xs-20{
        padding-bottom:20px!important
    }
    .p-2xs-24,.pb-2xs-24,.py-2xs-24{
        padding-bottom:24px!important
    }
    .p-2xs-28,.pb-2xs-28,.py-2xs-28{
        padding-bottom:28px!important
    }
    .p-2xs-32,.pb-2xs-32,.py-2xs-32{
        padding-bottom:32px!important
    }
    .p-2xs-36,.pb-2xs-36,.py-2xs-36{
        padding-bottom:36px!important
    }
    .p-2xs-40,.pb-2xs-40,.py-2xs-40{
        padding-bottom:40px!important
    }
    .p-2xs-44,.pb-2xs-44,.py-2xs-44{
        padding-bottom:44px!important
    }
    .p-2xs-48,.pb-2xs-48,.py-2xs-48{
        padding-bottom:48px!important
    }
    .p-2xs-60,.pb-2xs-60,.py-2xs-60{
        padding-bottom:60px!important
    }
    .p-2xs-80,.pb-2xs-80,.py-2xs-80{
        padding-bottom:80px!important
    }
    .p-2xs-120,.pb-2xs-120,.py-2xs-120{
        padding-bottom:120px!important
    }
    .p-2xs-0,.pl-2xs-0,.px-2xs-0{
        padding-left:0!important
    }
    .p-2xs-4,.pl-2xs-4,.px-2xs-4{
        padding-left:4px!important
    }
    .p-2xs-8,.pl-2xs-8,.px-2xs-8{
        padding-left:8px!important
    }
    .p-2xs-12,.pl-2xs-12,.px-2xs-12{
        padding-left:12px!important
    }
    .p-2xs-16,.pl-2xs-16,.px-2xs-16{
        padding-left:16px!important
    }
    .p-2xs-20,.pl-2xs-20,.px-2xs-20{
        padding-left:20px!important
    }
    .p-2xs-24,.pl-2xs-24,.px-2xs-24{
        padding-left:24px!important
    }
    .p-2xs-28,.pl-2xs-28,.px-2xs-28{
        padding-left:28px!important
    }
    .p-2xs-32,.pl-2xs-32,.px-2xs-32{
        padding-left:32px!important
    }
    .p-2xs-36,.pl-2xs-36,.px-2xs-36{
        padding-left:36px!important
    }
    .p-2xs-40,.pl-2xs-40,.px-2xs-40{
        padding-left:40px!important
    }
    .p-2xs-44,.pl-2xs-44,.px-2xs-44{
        padding-left:44px!important
    }
    .p-2xs-48,.pl-2xs-48,.px-2xs-48{
        padding-left:48px!important
    }
    .p-2xs-60,.pl-2xs-60,.px-2xs-60{
        padding-left:60px!important
    }
    .p-2xs-80,.pl-2xs-80,.px-2xs-80{
        padding-left:80px!important
    }
    .p-2xs-120,.pl-2xs-120,.px-2xs-120{
        padding-left:120px!important
    }
    .p-2xs-0,.pr-2xs-0,.px-2xs-0{
        padding-right:0!important
    }
    .p-2xs-4,.pr-2xs-4,.px-2xs-4{
        padding-right:4px!important
    }
    .p-2xs-8,.pr-2xs-8,.px-2xs-8{
        padding-right:8px!important
    }
    .p-2xs-12,.pr-2xs-12,.px-2xs-12{
        padding-right:12px!important
    }
    .p-2xs-16,.pr-2xs-16,.px-2xs-16{
        padding-right:16px!important
    }
    .p-2xs-20,.pr-2xs-20,.px-2xs-20{
        padding-right:20px!important
    }
    .p-2xs-24,.pr-2xs-24,.px-2xs-24{
        padding-right:24px!important
    }
    .p-2xs-28,.pr-2xs-28,.px-2xs-28{
        padding-right:28px!important
    }
    .p-2xs-32,.pr-2xs-32,.px-2xs-32{
        padding-right:32px!important
    }
    .p-2xs-36,.pr-2xs-36,.px-2xs-36{
        padding-right:36px!important
    }
    .p-2xs-40,.pr-2xs-40,.px-2xs-40{
        padding-right:40px!important
    }
    .p-2xs-44,.pr-2xs-44,.px-2xs-44{
        padding-right:44px!important
    }
    .p-2xs-48,.pr-2xs-48,.px-2xs-48{
        padding-right:48px!important
    }
    .p-2xs-60,.pr-2xs-60,.px-2xs-60{
        padding-right:60px!important
    }
    .p-2xs-80,.pr-2xs-80,.px-2xs-80{
        padding-right:80px!important
    }
    .p-2xs-120,.pr-2xs-120,.px-2xs-120{
        padding-right:120px!important
    }
}
@media (min-width:640px){
    .p-xs-0,.pt-xs-0,.py-xs-0{
        padding-top:0!important
    }
    .p-xs-4,.pt-xs-4,.py-xs-4{
        padding-top:4px!important
    }
    .p-xs-8,.pt-xs-8,.py-xs-8{
        padding-top:8px!important
    }
    .p-xs-12,.pt-xs-12,.py-xs-12{
        padding-top:12px!important
    }
    .p-xs-16,.pt-xs-16,.py-xs-16{
        padding-top:16px!important
    }
    .p-xs-20,.pt-xs-20,.py-xs-20{
        padding-top:20px!important
    }
    .p-xs-24,.pt-xs-24,.py-xs-24{
        padding-top:24px!important
    }
    .p-xs-28,.pt-xs-28,.py-xs-28{
        padding-top:28px!important
    }
    .p-xs-32,.pt-xs-32,.py-xs-32{
        padding-top:32px!important
    }
    .p-xs-36,.pt-xs-36,.py-xs-36{
        padding-top:36px!important
    }
    .p-xs-40,.pt-xs-40,.py-xs-40{
        padding-top:40px!important
    }
    .p-xs-44,.pt-xs-44,.py-xs-44{
        padding-top:44px!important
    }
    .p-xs-48,.pt-xs-48,.py-xs-48{
        padding-top:48px!important
    }
    .p-xs-60,.pt-xs-60,.py-xs-60{
        padding-top:60px!important
    }
    .p-xs-80,.pt-xs-80,.py-xs-80{
        padding-top:80px!important
    }
    .p-xs-120,.pt-xs-120,.py-xs-120{
        padding-top:120px!important
    }
    .p-xs-0,.pb-xs-0,.py-xs-0{
        padding-bottom:0!important
    }
    .p-xs-4,.pb-xs-4,.py-xs-4{
        padding-bottom:4px!important
    }
    .p-xs-8,.pb-xs-8,.py-xs-8{
        padding-bottom:8px!important
    }
    .p-xs-12,.pb-xs-12,.py-xs-12{
        padding-bottom:12px!important
    }
    .p-xs-16,.pb-xs-16,.py-xs-16{
        padding-bottom:16px!important
    }
    .p-xs-20,.pb-xs-20,.py-xs-20{
        padding-bottom:20px!important
    }
    .p-xs-24,.pb-xs-24,.py-xs-24{
        padding-bottom:24px!important
    }
    .p-xs-28,.pb-xs-28,.py-xs-28{
        padding-bottom:28px!important
    }
    .p-xs-32,.pb-xs-32,.py-xs-32{
        padding-bottom:32px!important
    }
    .p-xs-36,.pb-xs-36,.py-xs-36{
        padding-bottom:36px!important
    }
    .p-xs-40,.pb-xs-40,.py-xs-40{
        padding-bottom:40px!important
    }
    .p-xs-44,.pb-xs-44,.py-xs-44{
        padding-bottom:44px!important
    }
    .p-xs-48,.pb-xs-48,.py-xs-48{
        padding-bottom:48px!important
    }
    .p-xs-60,.pb-xs-60,.py-xs-60{
        padding-bottom:60px!important
    }
    .p-xs-80,.pb-xs-80,.py-xs-80{
        padding-bottom:80px!important
    }
    .p-xs-120,.pb-xs-120,.py-xs-120{
        padding-bottom:120px!important
    }
    .p-xs-0,.pl-xs-0,.px-xs-0{
        padding-left:0!important
    }
    .p-xs-4,.pl-xs-4,.px-xs-4{
        padding-left:4px!important
    }
    .p-xs-8,.pl-xs-8,.px-xs-8{
        padding-left:8px!important
    }
    .p-xs-12,.pl-xs-12,.px-xs-12{
        padding-left:12px!important
    }
    .p-xs-16,.pl-xs-16,.px-xs-16{
        padding-left:16px!important
    }
    .p-xs-20,.pl-xs-20,.px-xs-20{
        padding-left:20px!important
    }
    .p-xs-24,.pl-xs-24,.px-xs-24{
        padding-left:24px!important
    }
    .p-xs-28,.pl-xs-28,.px-xs-28{
        padding-left:28px!important
    }
    .p-xs-32,.pl-xs-32,.px-xs-32{
        padding-left:32px!important
    }
    .p-xs-36,.pl-xs-36,.px-xs-36{
        padding-left:36px!important
    }
    .p-xs-40,.pl-xs-40,.px-xs-40{
        padding-left:40px!important
    }
    .p-xs-44,.pl-xs-44,.px-xs-44{
        padding-left:44px!important
    }
    .p-xs-48,.pl-xs-48,.px-xs-48{
        padding-left:48px!important
    }
    .p-xs-60,.pl-xs-60,.px-xs-60{
        padding-left:60px!important
    }
    .p-xs-80,.pl-xs-80,.px-xs-80{
        padding-left:80px!important
    }
    .p-xs-120,.pl-xs-120,.px-xs-120{
        padding-left:120px!important
    }
    .p-xs-0,.pr-xs-0,.px-xs-0{
        padding-right:0!important
    }
    .p-xs-4,.pr-xs-4,.px-xs-4{
        padding-right:4px!important
    }
    .p-xs-8,.pr-xs-8,.px-xs-8{
        padding-right:8px!important
    }
    .p-xs-12,.pr-xs-12,.px-xs-12{
        padding-right:12px!important
    }
    .p-xs-16,.pr-xs-16,.px-xs-16{
        padding-right:16px!important
    }
    .p-xs-20,.pr-xs-20,.px-xs-20{
        padding-right:20px!important
    }
    .p-xs-24,.pr-xs-24,.px-xs-24{
        padding-right:24px!important
    }
    .p-xs-28,.pr-xs-28,.px-xs-28{
        padding-right:28px!important
    }
    .p-xs-32,.pr-xs-32,.px-xs-32{
        padding-right:32px!important
    }
    .p-xs-36,.pr-xs-36,.px-xs-36{
        padding-right:36px!important
    }
    .p-xs-40,.pr-xs-40,.px-xs-40{
        padding-right:40px!important
    }
    .p-xs-44,.pr-xs-44,.px-xs-44{
        padding-right:44px!important
    }
    .p-xs-48,.pr-xs-48,.px-xs-48{
        padding-right:48px!important
    }
    .p-xs-60,.pr-xs-60,.px-xs-60{
        padding-right:60px!important
    }
    .p-xs-80,.pr-xs-80,.px-xs-80{
        padding-right:80px!important
    }
    .p-xs-120,.pr-xs-120,.px-xs-120{
        padding-right:120px!important
    }
}
@media (min-width:768px){
    .p-sm-0,.pt-sm-0,.py-sm-0{
        padding-top:0!important
    }
    .p-sm-4,.pt-sm-4,.py-sm-4{
        padding-top:4px!important
    }
    .p-sm-8,.pt-sm-8,.py-sm-8{
        padding-top:8px!important
    }
    .p-sm-12,.pt-sm-12,.py-sm-12{
        padding-top:12px!important
    }
    .p-sm-16,.pt-sm-16,.py-sm-16{
        padding-top:16px!important
    }
    .p-sm-20,.pt-sm-20,.py-sm-20{
        padding-top:20px!important
    }
    .p-sm-24,.pt-sm-24,.py-sm-24{
        padding-top:24px!important
    }
    .p-sm-28,.pt-sm-28,.py-sm-28{
        padding-top:28px!important
    }
    .p-sm-32,.pt-sm-32,.py-sm-32{
        padding-top:32px!important
    }
    .p-sm-36,.pt-sm-36,.py-sm-36{
        padding-top:36px!important
    }
    .p-sm-40,.pt-sm-40,.py-sm-40{
        padding-top:40px!important
    }
    .p-sm-44,.pt-sm-44,.py-sm-44{
        padding-top:44px!important
    }
    .p-sm-48,.pt-sm-48,.py-sm-48{
        padding-top:48px!important
    }
    .p-sm-60,.pt-sm-60,.py-sm-60{
        padding-top:60px!important
    }
    .p-sm-80,.pt-sm-80,.py-sm-80{
        padding-top:80px!important
    }
    .p-sm-120,.pt-sm-120,.py-sm-120{
        padding-top:120px!important
    }
    .p-sm-0,.pb-sm-0,.py-sm-0{
        padding-bottom:0!important
    }
    .p-sm-4,.pb-sm-4,.py-sm-4{
        padding-bottom:4px!important
    }
    .p-sm-8,.pb-sm-8,.py-sm-8{
        padding-bottom:8px!important
    }
    .p-sm-12,.pb-sm-12,.py-sm-12{
        padding-bottom:12px!important
    }
    .p-sm-16,.pb-sm-16,.py-sm-16{
        padding-bottom:16px!important
    }
    .p-sm-20,.pb-sm-20,.py-sm-20{
        padding-bottom:20px!important
    }
    .p-sm-24,.pb-sm-24,.py-sm-24{
        padding-bottom:24px!important
    }
    .p-sm-28,.pb-sm-28,.py-sm-28{
        padding-bottom:28px!important
    }
    .p-sm-32,.pb-sm-32,.py-sm-32{
        padding-bottom:32px!important
    }
    .p-sm-36,.pb-sm-36,.py-sm-36{
        padding-bottom:36px!important
    }
    .p-sm-40,.pb-sm-40,.py-sm-40{
        padding-bottom:40px!important
    }
    .p-sm-44,.pb-sm-44,.py-sm-44{
        padding-bottom:44px!important
    }
    .p-sm-48,.pb-sm-48,.py-sm-48{
        padding-bottom:48px!important
    }
    .p-sm-60,.pb-sm-60,.py-sm-60{
        padding-bottom:60px!important
    }
    .p-sm-80,.pb-sm-80,.py-sm-80{
        padding-bottom:80px!important
    }
    .p-sm-120,.pb-sm-120,.py-sm-120{
        padding-bottom:120px!important
    }
    .p-sm-0,.pl-sm-0,.px-sm-0{
        padding-left:0!important
    }
    .p-sm-4,.pl-sm-4,.px-sm-4{
        padding-left:4px!important
    }
    .p-sm-8,.pl-sm-8,.px-sm-8{
        padding-left:8px!important
    }
    .p-sm-12,.pl-sm-12,.px-sm-12{
        padding-left:12px!important
    }
    .p-sm-16,.pl-sm-16,.px-sm-16{
        padding-left:16px!important
    }
    .p-sm-20,.pl-sm-20,.px-sm-20{
        padding-left:20px!important
    }
    .p-sm-24,.pl-sm-24,.px-sm-24{
        padding-left:24px!important
    }
    .p-sm-28,.pl-sm-28,.px-sm-28{
        padding-left:28px!important
    }
    .p-sm-32,.pl-sm-32,.px-sm-32{
        padding-left:32px!important
    }
    .p-sm-36,.pl-sm-36,.px-sm-36{
        padding-left:36px!important
    }
    .p-sm-40,.pl-sm-40,.px-sm-40{
        padding-left:40px!important
    }
    .p-sm-44,.pl-sm-44,.px-sm-44{
        padding-left:44px!important
    }
    .p-sm-48,.pl-sm-48,.px-sm-48{
        padding-left:48px!important
    }
    .p-sm-60,.pl-sm-60,.px-sm-60{
        padding-left:60px!important
    }
    .p-sm-80,.pl-sm-80,.px-sm-80{
        padding-left:80px!important
    }
    .p-sm-120,.pl-sm-120,.px-sm-120{
        padding-left:120px!important
    }
    .p-sm-0,.pr-sm-0,.px-sm-0{
        padding-right:0!important
    }
    .p-sm-4,.pr-sm-4,.px-sm-4{
        padding-right:4px!important
    }
    .p-sm-8,.pr-sm-8,.px-sm-8{
        padding-right:8px!important
    }
    .p-sm-12,.pr-sm-12,.px-sm-12{
        padding-right:12px!important
    }
    .p-sm-16,.pr-sm-16,.px-sm-16{
        padding-right:16px!important
    }
    .p-sm-20,.pr-sm-20,.px-sm-20{
        padding-right:20px!important
    }
    .p-sm-24,.pr-sm-24,.px-sm-24{
        padding-right:24px!important
    }
    .p-sm-28,.pr-sm-28,.px-sm-28{
        padding-right:28px!important
    }
    .p-sm-32,.pr-sm-32,.px-sm-32{
        padding-right:32px!important
    }
    .p-sm-36,.pr-sm-36,.px-sm-36{
        padding-right:36px!important
    }
    .p-sm-40,.pr-sm-40,.px-sm-40{
        padding-right:40px!important
    }
    .p-sm-44,.pr-sm-44,.px-sm-44{
        padding-right:44px!important
    }
    .p-sm-48,.pr-sm-48,.px-sm-48{
        padding-right:48px!important
    }
    .p-sm-60,.pr-sm-60,.px-sm-60{
        padding-right:60px!important
    }
    .p-sm-80,.pr-sm-80,.px-sm-80{
        padding-right:80px!important
    }
    .p-sm-120,.pr-sm-120,.px-sm-120{
        padding-right:120px!important
    }
}
@media (min-width:1024px){
    .p-md-0,.pt-md-0,.py-md-0{
        padding-top:0!important
    }
    .p-md-4,.pt-md-4,.py-md-4{
        padding-top:4px!important
    }
    .p-md-8,.pt-md-8,.py-md-8{
        padding-top:8px!important
    }
    .p-md-12,.pt-md-12,.py-md-12{
        padding-top:12px!important
    }
    .p-md-16,.pt-md-16,.py-md-16{
        padding-top:16px!important
    }
    .p-md-20,.pt-md-20,.py-md-20{
        padding-top:20px!important
    }
    .p-md-24,.pt-md-24,.py-md-24{
        padding-top:24px!important
    }
    .p-md-28,.pt-md-28,.py-md-28{
        padding-top:28px!important
    }
    .p-md-32,.pt-md-32,.py-md-32{
        padding-top:32px!important
    }
    .p-md-36,.pt-md-36,.py-md-36{
        padding-top:36px!important
    }
    .p-md-40,.pt-md-40,.py-md-40{
        padding-top:40px!important
    }
    .p-md-44,.pt-md-44,.py-md-44{
        padding-top:44px!important
    }
    .p-md-48,.pt-md-48,.py-md-48{
        padding-top:48px!important
    }
    .p-md-60,.pt-md-60,.py-md-60{
        padding-top:60px!important
    }
    .p-md-80,.pt-md-80,.py-md-80{
        padding-top:80px!important
    }
    .p-md-120,.pt-md-120,.py-md-120{
        padding-top:120px!important
    }
    .p-md-0,.pb-md-0,.py-md-0{
        padding-bottom:0!important
    }
    .p-md-4,.pb-md-4,.py-md-4{
        padding-bottom:4px!important
    }
    .p-md-8,.pb-md-8,.py-md-8{
        padding-bottom:8px!important
    }
    .p-md-12,.pb-md-12,.py-md-12{
        padding-bottom:12px!important
    }
    .p-md-16,.pb-md-16,.py-md-16{
        padding-bottom:16px!important
    }
    .p-md-20,.pb-md-20,.py-md-20{
        padding-bottom:20px!important
    }
    .p-md-24,.pb-md-24,.py-md-24{
        padding-bottom:24px!important
    }
    .p-md-28,.pb-md-28,.py-md-28{
        padding-bottom:28px!important
    }
    .p-md-32,.pb-md-32,.py-md-32{
        padding-bottom:32px!important
    }
    .p-md-36,.pb-md-36,.py-md-36{
        padding-bottom:36px!important
    }
    .p-md-40,.pb-md-40,.py-md-40{
        padding-bottom:40px!important
    }
    .p-md-44,.pb-md-44,.py-md-44{
        padding-bottom:44px!important
    }
    .p-md-48,.pb-md-48,.py-md-48{
        padding-bottom:48px!important
    }
    .p-md-60,.pb-md-60,.py-md-60{
        padding-bottom:60px!important
    }
    .p-md-80,.pb-md-80,.py-md-80{
        padding-bottom:80px!important
    }
    .p-md-120,.pb-md-120,.py-md-120{
        padding-bottom:120px!important
    }
    .p-md-0,.pl-md-0,.px-md-0{
        padding-left:0!important
    }
    .p-md-4,.pl-md-4,.px-md-4{
        padding-left:4px!important
    }
    .p-md-8,.pl-md-8,.px-md-8{
        padding-left:8px!important
    }
    .p-md-12,.pl-md-12,.px-md-12{
        padding-left:12px!important
    }
    .p-md-16,.pl-md-16,.px-md-16{
        padding-left:16px!important
    }
    .p-md-20,.pl-md-20,.px-md-20{
        padding-left:20px!important
    }
    .p-md-24,.pl-md-24,.px-md-24{
        padding-left:24px!important
    }
    .p-md-28,.pl-md-28,.px-md-28{
        padding-left:28px!important
    }
    .p-md-32,.pl-md-32,.px-md-32{
        padding-left:32px!important
    }
    .p-md-36,.pl-md-36,.px-md-36{
        padding-left:36px!important
    }
    .p-md-40,.pl-md-40,.px-md-40{
        padding-left:40px!important
    }
    .p-md-44,.pl-md-44,.px-md-44{
        padding-left:44px!important
    }
    .p-md-48,.pl-md-48,.px-md-48{
        padding-left:48px!important
    }
    .p-md-60,.pl-md-60,.px-md-60{
        padding-left:60px!important
    }
    .p-md-80,.pl-md-80,.px-md-80{
        padding-left:80px!important
    }
    .p-md-120,.pl-md-120,.px-md-120{
        padding-left:120px!important
    }
    .p-md-0,.pr-md-0,.px-md-0{
        padding-right:0!important
    }
    .p-md-4,.pr-md-4,.px-md-4{
        padding-right:4px!important
    }
    .p-md-8,.pr-md-8,.px-md-8{
        padding-right:8px!important
    }
    .p-md-12,.pr-md-12,.px-md-12{
        padding-right:12px!important
    }
    .p-md-16,.pr-md-16,.px-md-16{
        padding-right:16px!important
    }
    .p-md-20,.pr-md-20,.px-md-20{
        padding-right:20px!important
    }
    .p-md-24,.pr-md-24,.px-md-24{
        padding-right:24px!important
    }
    .p-md-28,.pr-md-28,.px-md-28{
        padding-right:28px!important
    }
    .p-md-32,.pr-md-32,.px-md-32{
        padding-right:32px!important
    }
    .p-md-36,.pr-md-36,.px-md-36{
        padding-right:36px!important
    }
    .p-md-40,.pr-md-40,.px-md-40{
        padding-right:40px!important
    }
    .p-md-44,.pr-md-44,.px-md-44{
        padding-right:44px!important
    }
    .p-md-48,.pr-md-48,.px-md-48{
        padding-right:48px!important
    }
    .p-md-60,.pr-md-60,.px-md-60{
        padding-right:60px!important
    }
    .p-md-80,.pr-md-80,.px-md-80{
        padding-right:80px!important
    }
    .p-md-120,.pr-md-120,.px-md-120{
        padding-right:120px!important
    }
}
@media (min-width:1280px){
    .p-lg-0,.pt-lg-0,.py-lg-0{
        padding-top:0!important
    }
    .p-lg-4,.pt-lg-4,.py-lg-4{
        padding-top:4px!important
    }
    .p-lg-8,.pt-lg-8,.py-lg-8{
        padding-top:8px!important
    }
    .p-lg-12,.pt-lg-12,.py-lg-12{
        padding-top:12px!important
    }
    .p-lg-16,.pt-lg-16,.py-lg-16{
        padding-top:16px!important
    }
    .p-lg-20,.pt-lg-20,.py-lg-20{
        padding-top:20px!important
    }
    .p-lg-24,.pt-lg-24,.py-lg-24{
        padding-top:24px!important
    }
    .p-lg-28,.pt-lg-28,.py-lg-28{
        padding-top:28px!important
    }
    .p-lg-32,.pt-lg-32,.py-lg-32{
        padding-top:32px!important
    }
    .p-lg-36,.pt-lg-36,.py-lg-36{
        padding-top:36px!important
    }
    .p-lg-40,.pt-lg-40,.py-lg-40{
        padding-top:40px!important
    }
    .p-lg-44,.pt-lg-44,.py-lg-44{
        padding-top:44px!important
    }
    .p-lg-48,.pt-lg-48,.py-lg-48{
        padding-top:48px!important
    }
    .p-lg-60,.pt-lg-60,.py-lg-60{
        padding-top:60px!important
    }
    .p-lg-80,.pt-lg-80,.py-lg-80{
        padding-top:80px!important
    }
    .p-lg-120,.pt-lg-120,.py-lg-120{
        padding-top:120px!important
    }
    .p-lg-0,.pb-lg-0,.py-lg-0{
        padding-bottom:0!important
    }
    .p-lg-4,.pb-lg-4,.py-lg-4{
        padding-bottom:4px!important
    }
    .p-lg-8,.pb-lg-8,.py-lg-8{
        padding-bottom:8px!important
    }
    .p-lg-12,.pb-lg-12,.py-lg-12{
        padding-bottom:12px!important
    }
    .p-lg-16,.pb-lg-16,.py-lg-16{
        padding-bottom:16px!important
    }
    .p-lg-20,.pb-lg-20,.py-lg-20{
        padding-bottom:20px!important
    }
    .p-lg-24,.pb-lg-24,.py-lg-24{
        padding-bottom:24px!important
    }
    .p-lg-28,.pb-lg-28,.py-lg-28{
        padding-bottom:28px!important
    }
    .p-lg-32,.pb-lg-32,.py-lg-32{
        padding-bottom:32px!important
    }
    .p-lg-36,.pb-lg-36,.py-lg-36{
        padding-bottom:36px!important
    }
    .p-lg-40,.pb-lg-40,.py-lg-40{
        padding-bottom:40px!important
    }
    .p-lg-44,.pb-lg-44,.py-lg-44{
        padding-bottom:44px!important
    }
    .p-lg-48,.pb-lg-48,.py-lg-48{
        padding-bottom:48px!important
    }
    .p-lg-60,.pb-lg-60,.py-lg-60{
        padding-bottom:60px!important
    }
    .p-lg-80,.pb-lg-80,.py-lg-80{
        padding-bottom:80px!important
    }
    .p-lg-120,.pb-lg-120,.py-lg-120{
        padding-bottom:120px!important
    }
    .p-lg-0,.pl-lg-0,.px-lg-0{
        padding-left:0!important
    }
    .p-lg-4,.pl-lg-4,.px-lg-4{
        padding-left:4px!important
    }
    .p-lg-8,.pl-lg-8,.px-lg-8{
        padding-left:8px!important
    }
    .p-lg-12,.pl-lg-12,.px-lg-12{
        padding-left:12px!important
    }
    .p-lg-16,.pl-lg-16,.px-lg-16{
        padding-left:16px!important
    }
    .p-lg-20,.pl-lg-20,.px-lg-20{
        padding-left:20px!important
    }
    .p-lg-24,.pl-lg-24,.px-lg-24{
        padding-left:24px!important
    }
    .p-lg-28,.pl-lg-28,.px-lg-28{
        padding-left:28px!important
    }
    .p-lg-32,.pl-lg-32,.px-lg-32{
        padding-left:32px!important
    }
    .p-lg-36,.pl-lg-36,.px-lg-36{
        padding-left:36px!important
    }
    .p-lg-40,.pl-lg-40,.px-lg-40{
        padding-left:40px!important
    }
    .p-lg-44,.pl-lg-44,.px-lg-44{
        padding-left:44px!important
    }
    .p-lg-48,.pl-lg-48,.px-lg-48{
        padding-left:48px!important
    }
    .p-lg-60,.pl-lg-60,.px-lg-60{
        padding-left:60px!important
    }
    .p-lg-80,.pl-lg-80,.px-lg-80{
        padding-left:80px!important
    }
    .p-lg-120,.pl-lg-120,.px-lg-120{
        padding-left:120px!important
    }
    .p-lg-0,.pr-lg-0,.px-lg-0{
        padding-right:0!important
    }
    .p-lg-4,.pr-lg-4,.px-lg-4{
        padding-right:4px!important
    }
    .p-lg-8,.pr-lg-8,.px-lg-8{
        padding-right:8px!important
    }
    .p-lg-12,.pr-lg-12,.px-lg-12{
        padding-right:12px!important
    }
    .p-lg-16,.pr-lg-16,.px-lg-16{
        padding-right:16px!important
    }
    .p-lg-20,.pr-lg-20,.px-lg-20{
        padding-right:20px!important
    }
    .p-lg-24,.pr-lg-24,.px-lg-24{
        padding-right:24px!important
    }
    .p-lg-28,.pr-lg-28,.px-lg-28{
        padding-right:28px!important
    }
    .p-lg-32,.pr-lg-32,.px-lg-32{
        padding-right:32px!important
    }
    .p-lg-36,.pr-lg-36,.px-lg-36{
        padding-right:36px!important
    }
    .p-lg-40,.pr-lg-40,.px-lg-40{
        padding-right:40px!important
    }
    .p-lg-44,.pr-lg-44,.px-lg-44{
        padding-right:44px!important
    }
    .p-lg-48,.pr-lg-48,.px-lg-48{
        padding-right:48px!important
    }
    .p-lg-60,.pr-lg-60,.px-lg-60{
        padding-right:60px!important
    }
    .p-lg-80,.pr-lg-80,.px-lg-80{
        padding-right:80px!important
    }
    .p-lg-120,.pr-lg-120,.px-lg-120{
        padding-right:120px!important
    }
}
@media (min-width:1360px){
    .p-xl-0,.pt-xl-0,.py-xl-0{
        padding-top:0!important
    }
    .p-xl-4,.pt-xl-4,.py-xl-4{
        padding-top:4px!important
    }
    .p-xl-8,.pt-xl-8,.py-xl-8{
        padding-top:8px!important
    }
    .p-xl-12,.pt-xl-12,.py-xl-12{
        padding-top:12px!important
    }
    .p-xl-16,.pt-xl-16,.py-xl-16{
        padding-top:16px!important
    }
    .p-xl-20,.pt-xl-20,.py-xl-20{
        padding-top:20px!important
    }
    .p-xl-24,.pt-xl-24,.py-xl-24{
        padding-top:24px!important
    }
    .p-xl-28,.pt-xl-28,.py-xl-28{
        padding-top:28px!important
    }
    .p-xl-32,.pt-xl-32,.py-xl-32{
        padding-top:32px!important
    }
    .p-xl-36,.pt-xl-36,.py-xl-36{
        padding-top:36px!important
    }
    .p-xl-40,.pt-xl-40,.py-xl-40{
        padding-top:40px!important
    }
    .p-xl-44,.pt-xl-44,.py-xl-44{
        padding-top:44px!important
    }
    .p-xl-48,.pt-xl-48,.py-xl-48{
        padding-top:48px!important
    }
    .p-xl-60,.pt-xl-60,.py-xl-60{
        padding-top:60px!important
    }
    .p-xl-80,.pt-xl-80,.py-xl-80{
        padding-top:80px!important
    }
    .p-xl-120,.pt-xl-120,.py-xl-120{
        padding-top:120px!important
    }
    .p-xl-0,.pb-xl-0,.py-xl-0{
        padding-bottom:0!important
    }
    .p-xl-4,.pb-xl-4,.py-xl-4{
        padding-bottom:4px!important
    }
    .p-xl-8,.pb-xl-8,.py-xl-8{
        padding-bottom:8px!important
    }
    .p-xl-12,.pb-xl-12,.py-xl-12{
        padding-bottom:12px!important
    }
    .p-xl-16,.pb-xl-16,.py-xl-16{
        padding-bottom:16px!important
    }
    .p-xl-20,.pb-xl-20,.py-xl-20{
        padding-bottom:20px!important
    }
    .p-xl-24,.pb-xl-24,.py-xl-24{
        padding-bottom:24px!important
    }
    .p-xl-28,.pb-xl-28,.py-xl-28{
        padding-bottom:28px!important
    }
    .p-xl-32,.pb-xl-32,.py-xl-32{
        padding-bottom:32px!important
    }
    .p-xl-36,.pb-xl-36,.py-xl-36{
        padding-bottom:36px!important
    }
    .p-xl-40,.pb-xl-40,.py-xl-40{
        padding-bottom:40px!important
    }
    .p-xl-44,.pb-xl-44,.py-xl-44{
        padding-bottom:44px!important
    }
    .p-xl-48,.pb-xl-48,.py-xl-48{
        padding-bottom:48px!important
    }
    .p-xl-60,.pb-xl-60,.py-xl-60{
        padding-bottom:60px!important
    }
    .p-xl-80,.pb-xl-80,.py-xl-80{
        padding-bottom:80px!important
    }
    .p-xl-120,.pb-xl-120,.py-xl-120{
        padding-bottom:120px!important
    }
    .p-xl-0,.pl-xl-0,.px-xl-0{
        padding-left:0!important
    }
    .p-xl-4,.pl-xl-4,.px-xl-4{
        padding-left:4px!important
    }
    .p-xl-8,.pl-xl-8,.px-xl-8{
        padding-left:8px!important
    }
    .p-xl-12,.pl-xl-12,.px-xl-12{
        padding-left:12px!important
    }
    .p-xl-16,.pl-xl-16,.px-xl-16{
        padding-left:16px!important
    }
    .p-xl-20,.pl-xl-20,.px-xl-20{
        padding-left:20px!important
    }
    .p-xl-24,.pl-xl-24,.px-xl-24{
        padding-left:24px!important
    }
    .p-xl-28,.pl-xl-28,.px-xl-28{
        padding-left:28px!important
    }
    .p-xl-32,.pl-xl-32,.px-xl-32{
        padding-left:32px!important
    }
    .p-xl-36,.pl-xl-36,.px-xl-36{
        padding-left:36px!important
    }
    .p-xl-40,.pl-xl-40,.px-xl-40{
        padding-left:40px!important
    }
    .p-xl-44,.pl-xl-44,.px-xl-44{
        padding-left:44px!important
    }
    .p-xl-48,.pl-xl-48,.px-xl-48{
        padding-left:48px!important
    }
    .p-xl-60,.pl-xl-60,.px-xl-60{
        padding-left:60px!important
    }
    .p-xl-80,.pl-xl-80,.px-xl-80{
        padding-left:80px!important
    }
    .p-xl-120,.pl-xl-120,.px-xl-120{
        padding-left:120px!important
    }
    .p-xl-0,.pr-xl-0,.px-xl-0{
        padding-right:0!important
    }
    .p-xl-4,.pr-xl-4,.px-xl-4{
        padding-right:4px!important
    }
    .p-xl-8,.pr-xl-8,.px-xl-8{
        padding-right:8px!important
    }
    .p-xl-12,.pr-xl-12,.px-xl-12{
        padding-right:12px!important
    }
    .p-xl-16,.pr-xl-16,.px-xl-16{
        padding-right:16px!important
    }
    .p-xl-20,.pr-xl-20,.px-xl-20{
        padding-right:20px!important
    }
    .p-xl-24,.pr-xl-24,.px-xl-24{
        padding-right:24px!important
    }
    .p-xl-28,.pr-xl-28,.px-xl-28{
        padding-right:28px!important
    }
    .p-xl-32,.pr-xl-32,.px-xl-32{
        padding-right:32px!important
    }
    .p-xl-36,.pr-xl-36,.px-xl-36{
        padding-right:36px!important
    }
    .p-xl-40,.pr-xl-40,.px-xl-40{
        padding-right:40px!important
    }
    .p-xl-44,.pr-xl-44,.px-xl-44{
        padding-right:44px!important
    }
    .p-xl-48,.pr-xl-48,.px-xl-48{
        padding-right:48px!important
    }
    .p-xl-60,.pr-xl-60,.px-xl-60{
        padding-right:60px!important
    }
    .p-xl-80,.pr-xl-80,.px-xl-80{
        padding-right:80px!important
    }
    .p-xl-120,.pr-xl-120,.px-xl-120{
        padding-right:120px!important
    }
}
@media (min-width:1680px){
    .p-xxl-0,.pt-xxl-0,.py-xxl-0{
        padding-top:0!important
    }
    .p-xxl-4,.pt-xxl-4,.py-xxl-4{
        padding-top:4px!important
    }
    .p-xxl-8,.pt-xxl-8,.py-xxl-8{
        padding-top:8px!important
    }
    .p-xxl-12,.pt-xxl-12,.py-xxl-12{
        padding-top:12px!important
    }
    .p-xxl-16,.pt-xxl-16,.py-xxl-16{
        padding-top:16px!important
    }
    .p-xxl-20,.pt-xxl-20,.py-xxl-20{
        padding-top:20px!important
    }
    .p-xxl-24,.pt-xxl-24,.py-xxl-24{
        padding-top:24px!important
    }
    .p-xxl-28,.pt-xxl-28,.py-xxl-28{
        padding-top:28px!important
    }
    .p-xxl-32,.pt-xxl-32,.py-xxl-32{
        padding-top:32px!important
    }
    .p-xxl-36,.pt-xxl-36,.py-xxl-36{
        padding-top:36px!important
    }
    .p-xxl-40,.pt-xxl-40,.py-xxl-40{
        padding-top:40px!important
    }
    .p-xxl-44,.pt-xxl-44,.py-xxl-44{
        padding-top:44px!important
    }
    .p-xxl-48,.pt-xxl-48,.py-xxl-48{
        padding-top:48px!important
    }
    .p-xxl-60,.pt-xxl-60,.py-xxl-60{
        padding-top:60px!important
    }
    .p-xxl-80,.pt-xxl-80,.py-xxl-80{
        padding-top:80px!important
    }
    .p-xxl-120,.pt-xxl-120,.py-xxl-120{
        padding-top:120px!important
    }
    .p-xxl-0,.pb-xxl-0,.py-xxl-0{
        padding-bottom:0!important
    }
    .p-xxl-4,.pb-xxl-4,.py-xxl-4{
        padding-bottom:4px!important
    }
    .p-xxl-8,.pb-xxl-8,.py-xxl-8{
        padding-bottom:8px!important
    }
    .p-xxl-12,.pb-xxl-12,.py-xxl-12{
        padding-bottom:12px!important
    }
    .p-xxl-16,.pb-xxl-16,.py-xxl-16{
        padding-bottom:16px!important
    }
    .p-xxl-20,.pb-xxl-20,.py-xxl-20{
        padding-bottom:20px!important
    }
    .p-xxl-24,.pb-xxl-24,.py-xxl-24{
        padding-bottom:24px!important
    }
    .p-xxl-28,.pb-xxl-28,.py-xxl-28{
        padding-bottom:28px!important
    }
    .p-xxl-32,.pb-xxl-32,.py-xxl-32{
        padding-bottom:32px!important
    }
    .p-xxl-36,.pb-xxl-36,.py-xxl-36{
        padding-bottom:36px!important
    }
    .p-xxl-40,.pb-xxl-40,.py-xxl-40{
        padding-bottom:40px!important
    }
    .p-xxl-44,.pb-xxl-44,.py-xxl-44{
        padding-bottom:44px!important
    }
    .p-xxl-48,.pb-xxl-48,.py-xxl-48{
        padding-bottom:48px!important
    }
    .p-xxl-60,.pb-xxl-60,.py-xxl-60{
        padding-bottom:60px!important
    }
    .p-xxl-80,.pb-xxl-80,.py-xxl-80{
        padding-bottom:80px!important
    }
    .p-xxl-120,.pb-xxl-120,.py-xxl-120{
        padding-bottom:120px!important
    }
    .p-xxl-0,.pl-xxl-0,.px-xxl-0{
        padding-left:0!important
    }
    .p-xxl-4,.pl-xxl-4,.px-xxl-4{
        padding-left:4px!important
    }
    .p-xxl-8,.pl-xxl-8,.px-xxl-8{
        padding-left:8px!important
    }
    .p-xxl-12,.pl-xxl-12,.px-xxl-12{
        padding-left:12px!important
    }
    .p-xxl-16,.pl-xxl-16,.px-xxl-16{
        padding-left:16px!important
    }
    .p-xxl-20,.pl-xxl-20,.px-xxl-20{
        padding-left:20px!important
    }
    .p-xxl-24,.pl-xxl-24,.px-xxl-24{
        padding-left:24px!important
    }
    .p-xxl-28,.pl-xxl-28,.px-xxl-28{
        padding-left:28px!important
    }
    .p-xxl-32,.pl-xxl-32,.px-xxl-32{
        padding-left:32px!important
    }
    .p-xxl-36,.pl-xxl-36,.px-xxl-36{
        padding-left:36px!important
    }
    .p-xxl-40,.pl-xxl-40,.px-xxl-40{
        padding-left:40px!important
    }
    .p-xxl-44,.pl-xxl-44,.px-xxl-44{
        padding-left:44px!important
    }
    .p-xxl-48,.pl-xxl-48,.px-xxl-48{
        padding-left:48px!important
    }
    .p-xxl-60,.pl-xxl-60,.px-xxl-60{
        padding-left:60px!important
    }
    .p-xxl-80,.pl-xxl-80,.px-xxl-80{
        padding-left:80px!important
    }
    .p-xxl-120,.pl-xxl-120,.px-xxl-120{
        padding-left:120px!important
    }
    .p-xxl-0,.pr-xxl-0,.px-xxl-0{
        padding-right:0!important
    }
    .p-xxl-4,.pr-xxl-4,.px-xxl-4{
        padding-right:4px!important
    }
    .p-xxl-8,.pr-xxl-8,.px-xxl-8{
        padding-right:8px!important
    }
    .p-xxl-12,.pr-xxl-12,.px-xxl-12{
        padding-right:12px!important
    }
    .p-xxl-16,.pr-xxl-16,.px-xxl-16{
        padding-right:16px!important
    }
    .p-xxl-20,.pr-xxl-20,.px-xxl-20{
        padding-right:20px!important
    }
    .p-xxl-24,.pr-xxl-24,.px-xxl-24{
        padding-right:24px!important
    }
    .p-xxl-28,.pr-xxl-28,.px-xxl-28{
        padding-right:28px!important
    }
    .p-xxl-32,.pr-xxl-32,.px-xxl-32{
        padding-right:32px!important
    }
    .p-xxl-36,.pr-xxl-36,.px-xxl-36{
        padding-right:36px!important
    }
    .p-xxl-40,.pr-xxl-40,.px-xxl-40{
        padding-right:40px!important
    }
    .p-xxl-44,.pr-xxl-44,.px-xxl-44{
        padding-right:44px!important
    }
    .p-xxl-48,.pr-xxl-48,.px-xxl-48{
        padding-right:48px!important
    }
    .p-xxl-60,.pr-xxl-60,.px-xxl-60{
        padding-right:60px!important
    }
    .p-xxl-80,.pr-xxl-80,.px-xxl-80{
        padding-right:80px!important
    }
    .p-xxl-120,.pr-xxl-120,.px-xxl-120{
        padding-right:120px!important
    }
}
.body{
    font-family:GolosTextWebRegular,sans-serif;
    font-weight:400;
    background-color:var(--color-silver);
    min-height:100vh;
    letter-spacing:.02em;
    overflow-x:hidden
}
.p-relative{
    position:relative
}
.container-fixed{
    width:100%;
    max-width:1440px;
    margin:0 auto;
    padding-left:16px;
    padding-right:16px
}
@media (min-width:768px){
    .container-fixed{
        padding-left:24px;
        padding-right:24px
    }
}
@media (min-width:1024px){
    .container-fixed{
        padding-left:60px;
        padding-right:60px
    }
}
.section{
    position:relative;
    border-radius:16px;
    width:100%;
    max-width:1800px;
    padding-top:48px;
    padding-bottom:48px
}
@media (min-width:640px){
    .section{
        padding-top:60px;
        padding-bottom:60px
    }
}
@media (min-width:768px){
    .section{
        padding-top:80px;
        padding-bottom:80px
    }
}
@media (min-width:1680px){
    .section{
        padding-top:120px;
        padding-bottom:120px
    }
}
.section--white{
    background-color:var(--color-white);
    box-shadow:0 8px 12px -4px rgba(0,0,0,.08)
}
.section--dark-blue{
    background-color:var(--color-graphite);
    box-shadow:0 8px 12px -4px rgba(0,0,0,.08)
}
.section .img{
    width:100%;
    height:auto
}
@keyframes fade-in-top-small{
    0%{
        -webkit-transform:translateY(-20px);
        transform:translateY(-20px);
        opacity:0
    }
    to{
        -webkit-transform:translateY(0);
        transform:translateY(0);
        opacity:1
    }
}
@keyframes fade-in-bottom-small{
    0%{
        -webkit-transform:translateY(20px);
        transform:translateY(20px);
        opacity:0
    }
    to{
        -webkit-transform:translateY(0);
        transform:translateY(0);
        opacity:1
    }
}
.header{
    position:absolute;
    width:100%;
    top:0;
    left:0;
    padding-top:16px;
    padding-bottom:16px
}
.header__logo{
    width:48px;
    height:48px;
    display:inline-block
}
.header .btn-icon.btn--red{
    display:none
}
@media (min-width:1024px){
    .header .btn--red{
        display:none
    }
}
.header--fixed{
    position:fixed;
    bottom:0;
    top:unset;
    animation-name:fade-in-bottom-small;
    animation-duration:.5s;
    box-shadow:0 -1px 3px 0 rgba(0,0,0,.15);
    background-color:var(--color-white);
    z-index:5;
    padding-top:8px;
    padding-bottom:8px
}
.header--fixed .btn--red,.header--fixed .btn-icon.btn--red{
    display:flex
}
@media (min-width:1024px){
    .header--fixed{
        top:0;
        bottom:unset;
        box-shadow:0 1px 3px 0 rgba(0,0,0,.15);
        animation-name:fade-in-top-small;
        animation-duration:.5s
    }
}
.burger-menu{
    display:block;
    width:48px;
    height:48px;
    padding:10px;
    cursor:pointer;
    position:relative
}
@media (min-width:1024px){
    .burger-menu{
        display:none
    }
}
.burger-menu .menu-line{
    position:absolute;
    height:2px;
    width:28px;
    border-radius:2px;
    background-color:var(--color-graphite);
    top:24px;
    left:10px
}
.burger-menu .menu-line:after,.burger-menu .menu-line:before{
    position:absolute;
    content:"";
    display:block;
    height:2px;
    border-radius:2px;
    width:100%;
    background-color:var(--color-graphite);
    transition:.3s
}
.burger-menu .menu-line:before{
    top:8px
}
.burger-menu .menu-line:after{
    bottom:8px
}
.menu-container{
    display:flex!important;
    flex:0 1 auto;
    flex-direction:column;
    row-gap:24px;
    padding:80px 40px 24px 24px;
    background-color:var(--color-white);
    position:fixed;
    left:-330px;
    top:0;
    width:320px;
    height:100vh;
    overflow-y:auto;
    z-index:4;
    transition:left .4s;
    box-shadow:2px 0 8px 0 rgba(0,0,0,.3)
}
.menu-container .btn-container{
    display:none
}
.menu-container .link-arrow{
    position:fixed;
    left:-330px;
    top:16px;
    transition:.4s
}
@media (min-width:1024px){
    .menu-container{
        display:flex!important;
        flex:0 1 auto;
        position:relative;
        flex-direction:row;
        padding:0;
        height:auto;
        width:auto;
        top:0;
        left:0;
        flex-grow:1;
        background-color:transparent;
        overflow:visible;
        transition:none;
        box-shadow:none
    }
    .menu-container .btn-container{
        display:flex
    }
}
.menu-container__link{
    display:inline-block;
    text-align:left;
    color:var(--color-graphite);
    transition:color .3s
}
.menu-container__link:hover,.menu-container__link:hover:visited{
    color:var(--color-bright-space)
}
.menu-container__link:visited{
    color:var(--color-graphite)
}
.menu-container__link.menu{
    font-size:17px;
    line-height:26px;
    letter-spacing:.02em;
    font-family:var(--font-medium);
    font-weight:500
}
@media (min-width:1280px){
    .menu-container__link.menu{
        font-size:18px;
        line-height:26px
    }
}
.menu-container__link.docs{
    font-size:16px;
    line-height:22px
}
.header-menu-control:checked+.burger-menu+.menu-container{
    display:flex;
    left:0
}
.header-menu-control:checked+.burger-menu+.menu-container .btn-container{
    display:flex
}
.header-menu-control:checked+.burger-menu+.menu-container .link-arrow{
    left:248px
}
.main{
    background-color:#ebf1ff;
    background-image:url(./main-back_mobile.svg);
    background-repeat:no-repeat;
    background-position:bottom center;
    background-size:cover;
    max-width:2880px;
    min-height:100vh;
    margin:0 auto;
    color:var(--color-space-gray);
    text-align:center;
    overflow:hidden;
    padding-top:88px;
    position:relative
}
@media (min-width:1024px){
    .main{
        background-image:url(./main-back_desk.svg)
    }
}
.main-container{
    position:relative;
    overflow:hidden;
    margin:60px 0 320px;
    padding:0 16px
}
@media (min-width:768px){
    .main-container{
        margin:80px 0 420px
    }
}
@media (min-width:1024px){
    .main-container{
        margin:80px 0 300px
    }
}
@media (max-width:640px){
    .main-container .btn--cta{
        padding:0 28px
    }
}
.main__header{
    font-size:76px;
    line-height:76px;
    text-align:center;
    font-family:var(--font-bold)
}
@media (min-width:768px){
    .main__header{
        font-size:96px;
        line-height:96px
    }
}
@media (min-width:1024px){
    .main__header{
        font-size:120px;
        line-height:120px
    }
}
.main__text{
    font-size:23px;
    line-height:26px;
    max-width:380px;
    font-family:var(--font-medium)
}
@media (min-width:1024px){
    .main__text{
        font-size:26px;
        line-height:32px
    }
}
@media (min-width:1024px){
    .main__text{
        font-size:28px;
        line-height:36px
    }
}
.btn{
    display:inline-flex;
    align-items:center;
    justify-content:center;
    white-space:nowrap;
    cursor:pointer;
    transition:.3s;
    border-radius:32px;
    font-family:GolosTextWebMedium,sans-serif;
    font-weight:500;
    box-shadow:0 1px 1px 0 rgba(0,0,0,.08);
    line-height:1px;
    transform:perspective(1px) translateZ(0);
    overflow:hidden
}
.btn:before{
    content:"";
    position:absolute;
    z-index:-1;
    top:0;
    left:0;
    right:0;
    bottom:0;
    transform:scaleY(0);
    transform-origin:50% 0;
    transition-duration:.2s;
    transition-timing-function:ease-out
}
.btn:hover:before{
    transform:scaleY(1)
}
.btn--sm{
    height:32px;
    padding:0 12px;
    font-size:15px
}
.btn--sm [class^=icon-]{
    font-size:20px;
    line-height:20px
}
.btn--sm.btn-icon{
    width:32px;
    padding:0
}
.btn--sm svg{
    width:20px;
    height:20px;
    flex-shrink:0;
    fill:#0092c7;
    fill-rule:evenodd
}
.btn--md{
    height:40px;
    padding:0 20px;
    font-size:15px
}
.btn--md svg{
    width:20px;
    height:20px;
    flex-shrink:0;
    fill:#0092c7;
    fill-rule:evenodd
}
.btn--lg{
    height:48px;
    padding:0 20px;
    font-size:16px;
    line-height:1
}
.btn--lg svg{
    width:20px;
    height:20px;
    flex-shrink:0;
    fill:#0092c7;
    fill-rule:evenodd
}
.btn--xl{
    height:56px;
    padding:0 24px;
    font-size:17px;
    letter-spacing:.02em;
    font-family:var(--font-regular);
    font-weight:400;
    column-gap:8px
}
.btn--xl [class^=icon-]{
    font-size:24px;
    line-height:24px
}
.btn--xl.btn-icon{
    width:56px;
    padding:0
}
.btn--xxl{
    height:64px;
    padding:0 32px;
    font-size:20px;
    letter-spacing:.02em;
    font-family:var(--font-regular)
}
.btn--cta,.btn--red{
    color:var(--color-white);
    background-color:var(--color-moscow)
}
.btn--cta:hover:before,.btn--red:hover:before{
    background-color:#ff1212
}
.btn--cta{
    box-shadow:0 12px 20px -7px rgba(255,69,69,.8)
}
.btn--primary{
    color:var(--color-white);
    background-color:var(--color-graphite)
}
.btn--primary:hover:before{
    background-color:var(--color-black);
    box-shadow:0 8px 10px -5px rgba(0,0,0,.4)
}
.btn--secondary{
    color:var(--color-graphite);
    background-color:var(--color-white);
    border:1px solid var(--color-silver)
}
.btn--secondary:hover:before{
    background-color:#f6f9ff
}
.btn--info{
    color:var(--color-graphite);
    background-color:var(--color-sky-yellow)
}
.btn--info:hover:before{
    background-color:#ffc820
}
.btn--delete{
    color:var(--color-moscow);
    background-color:transparent
}
.tabs{
    display:flex;
    flex-wrap:wrap;
    width:100%;
    column-gap:24px;
    row-gap:24px
}
.tab-item{
    font-family:var(--font-medium);
    font-weight:500;
    white-space:nowrap;
    border-radius:110px;
    transition:.2s;
    cursor:pointer;
    border:1px solid;
    box-shadow:0 1px 1px 0 rgba(0,0,0,.08)
}
.tab-item--header{
    color:var(--color-graphite);
    background-color:var(--color-white);
    border-color:var(--color-white)
}
.tab-item--header:hover{
    color:var(--color-graphite);
    background-color:var(--color-sky-yellow);
    border-color:var(--color-sky-yellow)
}
.tab-container{
    display:none;
    flex-direction:column;
    align-items:center;
    width:100%
}
.radio:checked+.tab-item--header{
    color:var(--color-graphite);
    background-color:var(--color-sky-yellow);
    border-color:var(--color-sky-yellow)
}
.radio:checked+.tab-item+.tab-container{
    display:flex!important;
    flex:0 1 auto;
    order:1
}
.modal{
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100vh;
    z-index:10;
    overflow-y:auto;
    overflow-x:hidden
}
.modal--app,.modal--feedback{
    background-color:var(--color-white)
}
.modal--app .link-arrow,.modal--feedback .link-arrow{
    position:absolute;
    right:16px;
    top:16px
}
.modal--app{
    background-image:url(./download-app.svg);
    background-repeat:no-repeat;
    background-position:top center;
    background-size:cover;
    min-height:100vh
}
@media (max-width:768px){
    .modal--app .h2{
        font-size:40px;
        line-height:44px
    }
}
.modal--app .tabs{
    column-gap:16px;
    row-gap:16px
}
@media (min-width:768px){
    .modal--app .tabs{
        column-gap:24px;
        row-gap:24px
    }
}
.modal--app .tab-item{
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:18px;
    height:48px;
    line-height:1px;
    padding:0 24px
}
@media (min-width:768px){
    .modal--app .tab-item{
        font-size:20px;
        height:56px
    }
}
.modal--app .code-container__qr{
    width:auto;
    background-color:var(--color-white);
    border-radius:24px;
    max-width:100%;
    height:calc(100vh - 280px);
    max-height:400px;
    box-shadow:0 4px 20px 0 rgba(0,0,0,.08)
}
.modal .feedback{
    width:100%;
    max-width:380px
}
.modal .feedback__header{
    font-family:var(--font-demibold);
    font-weight:600;
    font-size:32px;
    line-height:42px
}
@media (min-width:768px){
    .modal .feedback__header{
        font-size:44px;
        line-height:52px
    }
}
.modal .feedback--name,.modal .feedback--phone{
    width:100%
}
@media (min-width:480px){
    .modal .feedback--name,.modal .feedback--phone{
        width:calc(50% - 8px)
    }
}
.modal .feedback__label{
    font-size:15px;
    line-height:20px;
    color:var(--color-label)
}
.modal .feedback__icon{
    font-size:28px;
    line-height:28px
}
.modal .feedback__file-name{
    font-size:14px;
    line-height:18px;
    color:var(--color-black)
}
.modal .feedback__file-size{
    color:var(--color-label);
    font-size:11px;
    line-height:13px
}
.modal .feedback__policy{
    font-family:var(--font-regular);
    font-weight:400;
    color:var(--color-label);
    font-size:14px;
    line-height:18px
}
.modal .feedback__policy a{
    color:var(--color-label);
    text-decoration:underline
}
.modal .feedback__policy a:hover{
    text-decoration:none
}
.modal .message{
    text-align:center
}
.modal .message__icon{
    width:100px;
    height:100px
}
.modal .message__icon--good{
    fill:#2bcb1f
}
.modal .message__icon--bad{
    fill:#dc2626
}
.modal .message__text{
    font-family:var(--font-regular);
    font-weight:400;
    margin-top:32px;
    font-size:20px;
    line-height:28px;
    color:var(--color-space-gray)
}
.checkbox:not(:checked)+.modal{
    display:none!important
}
.checkbox:checked+.modal{
    display:flex
}
